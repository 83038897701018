import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { Alert, Box, Button, Grid, LinearProgress, Snackbar, TextField, Typography, useMediaQuery } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs'; // Ensure Dayjs is imported for type compatibility
import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { storage } from '../../firebase';
import { convertMealToTimelineEvent, createMeal, getGeminiNutrition, updateMeal } from '../../functions/nutrition';
import { Meal, NutritionInfo } from '../../types/nutrition';
import theme from '../utils/style/theme';
import { NutritionInfoEditor } from './nutition';
import { v4 as uuidv4 } from 'uuid';


const NutritionForm = ({ meal, onSave, onClose }: { meal: Meal, onSave: (meal: Meal) => void, onClose: () => void }) => {
  const { currentUser } = useAuth();
  const [image, setImage] = useState<File | null>(null);
  const [nutritionInfo, setNutritionInfo] = useState<NutritionInfo[]>(meal?.info || []);
  const [notes, setNotes] = useState(meal?.notes || '');
  const [uploading, setUploading] = useState(false);
  const [mealDate, setMealDate] =  React.useState<Timestamp | undefined>(meal?.createdAt) || undefined;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  interface UploadResult {
    url: string;
    uri: string;
  }

  const uploadImageAndGetURL = async (file: File, type: string): Promise<UploadResult> => {
    const uniqueFileName = `${uuidv4()}${file.name.substring(file.name.lastIndexOf('.'))}`;
  
    const storageRef = ref(storage, `meals/${currentUser.uid}/${type}/${uniqueFileName}`);
    const uri = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return { url, uri: uri.ref.toString() };
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setUploading(true);
      setImage(file);  // Set the image state for any other uses in the UI

      try {
        const upres = await uploadImageAndGetURL(file, "image");
        meal.image = upres.url;
        meal.uri = upres.uri;
        setSnackbarMessage('Image uploaded successfully');
        setSnackbarSeverity('success');
      
      } catch (error) {
        console.error('Error uploading file:', error);
        setSnackbarMessage('Error uploading image');
        setSnackbarSeverity('error');
      } finally {
        setUploading(false);
        setSnackbarOpen(true);
      }
    }
  };

  const getNutritionInfo = async () => {
    setUploading(true);
    try {
      if (meal.image != null) {
        const info = await getGeminiNutrition("image/jpeg", meal.uri, notes);
        if (Array.isArray(info)) {
          setNutritionInfo(info);
          setSnackbarMessage('Nutrition info fetched successfully');
          setSnackbarSeverity('success');
        }
      }
    } catch (error) {
      setSnackbarMessage('Error fetching nutrition info');
      setSnackbarSeverity('error');
    } finally {
      setUploading(false);
      setSnackbarOpen(true);
    }
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleNutritionInfoChange = (newNutritionInfo: NutritionInfo[]) => {
    setNutritionInfo(newNutritionInfo);
  };

  const publishMeal = async () => {
    const newMeal: Meal = {
      id: meal?.id || undefined,
      userId: currentUser.uid,
      image: meal.image || "",
      info: nutritionInfo,
      notes,
      createdAt: meal?.createdAt || Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    try {
      await convertMealToTimelineEvent(newMeal);
      setSnackbarMessage('Meal published successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Error publishing meal');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSave = async () => {
 


    const newMeal: Meal = {
      id: meal?.id || undefined,
      userId: currentUser.uid,
      image: meal.image || "",
      info: nutritionInfo,
      uri: meal.uri || "",
      notes,
      createdAt: mealDate,
      updatedAt: Timestamp.now(),
    };

    try {
      if (meal?.id) {
        await updateMeal(meal.id, newMeal);
        setSnackbarMessage('Meal updated successfully');
      } else {
        newMeal.id = "";  // Assuming this will be set by createMeal
        await createMeal(newMeal);
        setSnackbarMessage('Meal created successfully');
      }
      setSnackbarSeverity('success');
      onSave(newMeal);
    } catch (error) {
      setSnackbarMessage('Error saving meal');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };
  const dateForPicker = meal.createdAt ? dayjs(meal.createdAt.toDate()) : null;

  const handleDateChange = (newValue: Dayjs | null) => {
    const timestamp = newValue ? Timestamp.fromDate(newValue.toDate()) : undefined;
    setMealDate(timestamp);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSave}>
      <Typography variant="h6" sx={{ alignSelf: 'start' }}>
        {meal.id ? 'Edit Meal' : 'Add Meal'}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker value={dateForPicker} onChange={handleDateChange} />
      </LocalizationProvider>
      {meal && meal.image && (
        <img
          src={meal.image}
          alt="Preview"
          style={{
            display: 'block',
            maxWidth: '100%',
            width: isMobile ? '100%' : '40%',
            height: 'auto',
          }}
        />
      )}
      <TextField
        type="file"
        onChange={handleImageChange}
        fullWidth
        margin="normal"
        sx={{ mt: 2 }}
      />
      <TextField
        label="Notes"
        fullWidth
        multiline
        rows={4}
        value={notes}
        onChange={handleNotesChange}
        margin="normal"
        sx={{ width: '100%' }}
      />

      {uploading && (
        <Typography sx={{ mt: 2, mb: 2 }}>
          <LinearProgress color="success" />
        </Typography>
      )}

      <Button
        onClick={getNutritionInfo}
        color='warning'
        startIcon={<PrecisionManufacturingIcon />}
      >
        AI Nutrition Info
      </Button>
      <NutritionInfoEditor
        initialNutritionInfo={nutritionInfo}
        onNutritionInfoChange={handleNutritionInfoChange}
      />

      <Grid container spacing={1}>
        <Button
        color="warning"
          onClick={handleSave}
        >
          Save Meal
        </Button>
        <Button
          onClick={publishMeal}
        >
          Publish Meal
        </Button>
        <Button
          onClick={onClose}
          color="error"
        >
          Cancel
        </Button>
      </Grid>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={60000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default NutritionForm;
