import { Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const WeatherWidget: React.FC = () => {
    const [weather, setWeather] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');


    const fetchWeather = async (lat: number, lon: number) => {
     
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${ process.env.REACT_APP_OPENWEATHER_KEY}&units=imperial`;
     
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Weather data loading failed');
            const data = await response.json();
            setWeather(data);
        } catch (err) {
    setError('Failed to fetch weather data.');
    console.error(err);
  } finally {
    setIsLoading(false);
  }
};

    const getLocation = () => {
        if (!navigator.geolocation) {
          setError('Geolocation is not supported by your browser');
          setIsLoading(false);
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            fetchWeather(position.coords.latitude, position.coords.longitude);
          }, () => {
            setError('Unable to retrieve your location');
            setIsLoading(false);
          });
        }
      };
  
      useEffect(() => {
        getLocation();
      }, []);

    return (
        <Card raised>
            <CardContent>
                <Typography variant="h5">{weather?.name} Weather</Typography>
                <Typography  color="error" >Temperature: {weather?.main.temp} °F</Typography>
                <Typography color='#FFFF00' >Humidity: {weather?.main.humidity}%</Typography>
                <Typography color="#FFFF00">Condition: {weather?.weather[0].description}</Typography>
            </CardContent>
        </Card>
    );
};

export default WeatherWidget;
