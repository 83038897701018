import { collection, addDoc, Timestamp, updateDoc, deleteDoc, getDoc, doc, getDocs, query, where, orderBy, DocumentReference, DocumentSnapshot, limit, startAfter } from "firebase/firestore";
import { db, gem_nutrition_coach } from "../firebase";
import { Meal, NutritionInfo, UpdateMealFields } from "../types/nutrition";
import { fileToBase64, getBase64FromFirebaseStorage } from "./utils";
import { ContentItem, TimelineEvent } from "../types/timeline";
import { createTimelineEvent } from "./timeline";

async function createMeal(meal:  Partial<Omit<Meal, 'id' >>) {
  try {
    const docRef: DocumentReference = await addDoc(collection(db, "Meals"), {
      ...meal,
      createdAt: meal.createdAt || Timestamp.now(),
      updatedAt: Timestamp.now()
    });
 
    return docRef.id;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

async function getMeal(id: string) {
  const docRef = doc(db, "Meals", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    console.error("No such document!");
    return null;
  }
}


async function getSomeMeals(userId: string, pageSize: number, lastDoc?: Meal) {
  let q = query(
    collection(db, "Meals"),
    where("userId", "==", userId),
    orderBy("createdAt", "desc"),
    limit(pageSize)
  );

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(q);
  const fetchedMeals: Meal[] = [];
  querySnapshot.forEach(doc => {
    const meal = doc.data() as Meal;
    meal.id = doc.ref.id;
    fetchedMeals.push(meal);
  });

  // Get the last document for pagination
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  return {
    meals: fetchedMeals,
    lastDoc: lastVisible
  };
}

async function getMeals(userId: string) {
  const q = query(collection(db, "Meals"), where("userId", "==", userId), orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);
  const fetchedMeals: Meal[] = [];
  querySnapshot.forEach(doc => {
    const meal = doc.data() as Meal;
    meal.id = doc.ref.id
    fetchedMeals.push(meal);
  });
  return fetchedMeals;
}

async function getTodayMeals(userId: string, date ?: Date) {
  let startOfDay: Date
  let endOfDay: Date
  if (date){
    startOfDay = date
     startOfDay .setHours(0, 0, 0, 0);
    endOfDay = date
     endOfDay.setHours(23, 59, 59, 999);
  }else{
     startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    
     endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
  }


// Convert to Firestore Timestamps
const startTimestamp = Timestamp.fromDate(startOfDay);
const endTimestamp = Timestamp.fromDate(endOfDay);

// Update the Firestore query
const q = query(
  collection(db, "Meals"),
  where("userId", "==", userId),
  where("createdAt", ">=", startTimestamp),
  where("createdAt", "<=", endTimestamp),
  orderBy("createdAt", "desc")
);
 const querySnapshot = await getDocs(q);
  const fetchedMeals: Meal[] = [];
  querySnapshot.forEach(doc => {
    const meal = doc.data() as Meal;
    meal.id = doc.ref.id
    fetchedMeals.push(meal);
  });
  return fetchedMeals;
}

async function updateMeal(id: string, updatedFields: UpdateMealFields) {
  const docRef = doc(db, "Meals", id);
  try {
    await updateDoc(docRef, {
      ...updatedFields,
      updatedAt: Timestamp.now()
    });
    console.error("Document updated");
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}

async function deleteMeal(id: string) {
  const docRef = doc(db, "Meals", id);
  try {
    await deleteDoc(docRef);
    console.error("Document deleted");
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
}


async function getGeminiNutrition( mimeType: string, imageURL?: string, description?: string ): Promise<NutritionInfo[]> {
 var prompt =  description || "no description"

try {
if (imageURL) {
 
  const imagePart = { fileData: {mimeType: "image/jpeg", fileUri: imageURL }};
  const result = await gem_nutrition_coach.generateContent([prompt, imagePart]);
  const response = result.response;
  const outputs = (await response).text();
  const res = JSON.parse(outputs)as  NutritionInfo[];
  return res.map((item, index) => ({ ...item, id: `nutrition-${index}` }));

}else{
  const result = await gem_nutrition_coach.generateContent([prompt]);
  const response = result.response;
 
const outputs = (await response).text();
const res = JSON.parse(outputs)as  NutritionInfo[];
return res.map((item, index) => ({ ...item, id: `nutrition-${index}` }));

}
} catch (error) {
  console.error('Gemini Nutrition error:', error);
 
  return [];
}


}




function convertMealToTimelineEvent(meal: Meal) {
    const contentItems: ContentItem[] = [];
  
    // Add image to content if exists

    if (meal.image) {
      contentItems.push({
          type: 'image',
          url: meal.image,
          description: meal.notes, // Using notes as image description
          altText: 'Meal image'
        
      });
    }
  
  // Create a summary of all nutrition info
  let nutritionSummary = meal.info.map(info => 
    `${info.description}: ${info.calories} Calories, ${info.protein}g Protein, ${info.fat}g Fat, ${info.carbs}g Carbs. Key ingredients: ${info.key_ingredients.join(', ')}`
  ).join(' | ');

  // Append nutrition summary to notes
  const fullNotes = `${meal.notes} | Nutrition Summary: ${nutritionSummary}`;

  const tl = {
    id: meal.id,
    userId: meal.userId,
    title: 'Meal Summary',
    content: contentItems ,
    caption:fullNotes  ,
    likes: [], // Assuming no likes initially
    comments: [], // Assuming no comments initially
    createdAt: meal.createdAt || Timestamp.now(),
    updatedAt: meal.updatedAt  || Timestamp.now(),
    tags: ['nutrition', 'meal'], // Example tags, adjust as needed
    visibility: 'public' // or 'private' or 'friends', adjust as needed
  } as TimelineEvent;

    createTimelineEvent(tl)
    return 
  }

export { createMeal, getMeal,getSomeMeals, updateMeal, deleteMeal, getMeals , convertMealToTimelineEvent, getTodayMeals, getGeminiNutrition};
