import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Menu,
  MenuItem,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Timestamp } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { deleteLoggedWorkout, getWorkoutLog, logWorkout } from '../../functions/fitness';
import { Workout } from '../../types/fitness';
import WorkoutPlayer from './play_workout';
import { WorkoutCard, WorkoutForm } from './workout';

import MoreVertIcon from '@mui/icons-material/MoreVert';


const WorkoutLog: React.FC = () => {
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const [groupedWorkouts, setGroupedWorkouts] = useState<Map<string, Workout[]>>(new Map());
  const [workoutDates, setWorkoutDates] = useState<string[]>([]);
  const { currentUser } = useAuth();
  const stepperRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(moment());
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [playModalOpen, setPlayModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [workoutIdToDelete, setWorkoutIdToDelete] = useState<string | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuWorkout, setMenuWorkout] = useState<Workout | null>(null);

  useEffect(() => {
    loadWorkouts();
  }, [currentUser.uid]);

  const loadWorkouts = async () => {
    const loadedWorkouts = await getWorkoutLog(currentUser.uid);
    setWorkouts(loadedWorkouts);
    groupByDate(loadedWorkouts);
  };

  const groupByDate = (workouts: Workout[]) => {
    const grouped = new Map<string, Workout[]>();
    workouts.forEach((workout) => {
      const date = moment(workout.createdAt.toDate()).format('YYYY-MM-DD');
      const collection = grouped.get(date) || [];
      collection.push(workout);
      grouped.set(date, collection);
    });
    const sortedDates = Array.from(grouped.keys()).sort((a, b) => b.localeCompare(a));
    setGroupedWorkouts(grouped);
    setWorkoutDates(sortedDates);
    setSelectedDateIndex(sortedDates.indexOf(moment().format('YYYY-MM-DD')));
  };

  const scrollToStep = (index: number) => {
    const stepRef = stepperRefs.current[index];
    if (stepRef) {
        stepRef.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
};

  const handleDateChange = (date: moment.Moment | null) => {
    setSelectedDate(date);
    const formattedDate = date?.format('YYYY-MM-DD');
    if (formattedDate && workoutDates.includes(formattedDate)) {
      const index = workoutDates.indexOf(formattedDate);
      setSelectedDateIndex(index);
      scrollToStep(index);
    }
  };

  const handleExerciseClick = (workout: Workout) => {
    setIsEdit(true);
    setSelectedWorkout(workout);
    setModalOpen(true);
    handleMenuClose();
  };

  const handlePlayClick = (workout: Workout) => {
    setIsEdit(true);
    setSelectedWorkout(workout);
    setPlayModalOpen(true);
  };

  const handleDelete = (id?: string) => {
    if (id) {
      setWorkoutIdToDelete(id);
      setConfirmationDialogOpen(true);
      handleMenuClose();
    }
  };

  const handleCopy = async (workout: Workout) => {
    const newWorkout = {
      ...workout,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      id: "",
    };
    const w = await logWorkout({
      workout: newWorkout,
      userId: currentUser.uid
    });
    if (w) {
      const updatedWorkouts = [...workouts, w.workout];
      setWorkouts(updatedWorkouts);
      groupByDate(updatedWorkouts);
    }
    setSnackbarMessage("Copied workout to today!");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
    handleMenuClose();
  };

  const confirmDelete = async () => {
    if (workoutIdToDelete) {
      await deleteLoggedWorkout(workoutIdToDelete);
      const updatedWorkouts = workouts.filter((c) => c.id !== workoutIdToDelete);
      setWorkouts(updatedWorkouts);
      groupByDate(updatedWorkouts);
      setConfirmationDialogOpen(false);
      setWorkoutIdToDelete(null);
    }
  };

  useEffect(() => {
    stepperRefs.current = stepperRefs.current.slice(0, workoutDates.length);
  }, [workoutDates.length]);

  const handleClose = () => {
    loadWorkouts();
    setModalOpen(false);
  };

  const handlePlayClose = () => {
    setPlayModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setWorkoutIdToDelete(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, workout: Workout) => {
    setAnchorEl(event.currentTarget);
    setMenuWorkout(workout);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuWorkout(null);
  };

  return (
    <Box>
      <Box sx={{ position: 'sticky', top: 0, bgcolor: 'black', zIndex: 110, padding: 2 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker label="Pick a Date" value={selectedDate} onChange={handleDateChange} />
        </LocalizationProvider>
        <Button
          onClick={() => handleDateChange(moment())}
          color="primary"
          sx={{ mb: 2 }}
        >
          Go to Today
        </Button>
      </Box>

      <Stepper activeStep={selectedDateIndex} orientation="vertical" nonLinear>
        {workoutDates.map((date, index) => (
          <Step key={date} >
            <StepLabel
              error={groupedWorkouts.get(date)?.length === 0}
              onClick={() => setSelectedDateIndex(index)}
              sx={{ cursor: 'pointer' }}
            >
              {`${ moment(groupedWorkouts.get(date)?.at(0)?.createdAt.toDate()).format('dddd YYYY-MM-DD')} - ${groupedWorkouts.get(date)?.map((workout) => workout.title).join(', ')}`}
            </StepLabel>
            <StepContent>
              <List>
                {groupedWorkouts.get(date)?.map((workout) => (
                  <Card ref={(el) => (stepperRefs.current[index] = el)}>
                  <CardHeader
                    title={workout.title} // Adjust this to display the workout title or any other relevant info
                    action={
                      <>
                        <IconButton onClick={(event) => handleMenuClick(event, workout)}>
                          <MoreVertIcon color="primary" />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && menuWorkout?.id === workout.id}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={() => handleExerciseClick(workout)}>
                            <EditIcon fontSize="small" /> Edit
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(workout.id)}>
                            <DeleteIcon fontSize="small" color="error" /> Delete
                          </MenuItem>
                          <MenuItem onClick={() => handleCopy(workout)}>
                            <ContentCopyIcon fontSize="small" color="warning" /> Copy to Today
                          </MenuItem>
                        </Menu>
                      </>
                    }
                  />
                  <CardContent>
                    <WorkoutCard workout={workout} />
                  </CardContent>
                  <CardActions>
                    <Button color='secondary' startIcon={ <PlayCircleOutlineIcon color="secondary" />} onClick={() => handlePlayClick(workout)} >
                     Play Workout!
                    </Button>
                  </CardActions>
                </Card>
                ))}
              </List>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {selectedWorkout && (
        <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Edit Workout Log</DialogTitle>
          <DialogContent>
            <WorkoutForm
              programWorkout={selectedWorkout}
              log={true}
              open={modalOpen}
              onClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      )}

      {selectedWorkout && (
        <Dialog open={playModalOpen} onClose={handlePlayClose} fullScreen maxWidth="md">
          <DialogTitle>{selectedWorkout.title}</DialogTitle>
          <DialogContent>
            <WorkoutPlayer workout={selectedWorkout} handleClose={handlePlayClose} />
          </DialogContent>
        </Dialog>
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete this logged workout? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WorkoutLog;
