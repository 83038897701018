import {
  Alert,
  Button,
  CardActions,
  Container,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { CoachingSettings } from '../../types/fitness';
import MuiMarkdown from 'mui-markdown';
import { MDXEditor, toolbarPlugin, UndoRedo, BoldItalicUnderlineToggles, ListsToggle, listsPlugin, quotePlugin, headingsPlugin, linkPlugin, directivesPlugin, AdmonitionDirectiveDescriptor, linkDialogPlugin, imagePlugin, tablePlugin, thematicBreakPlugin, frontmatterPlugin, markdownShortcutPlugin } from '@mdxeditor/editor';
import { EditableMDXEditor } from '../utils/mardkown_render';

const CoachingSettingsComponent: React.FC = () => {
  const { currentUser } = useAuth();

  const initialSettings: CoachingSettings = {
    userId: currentUser.uid,
    habitDashKey: '',
    AICoachSystem: '',
    ExistsKey: '',
    preferredWorkouts: '',
    foodRestrictions: '',
    Gender: "Fem",
    height_cm: 0,
    weight: 0,
    weekly_avg_cals: 0,
    today_active_cal_burn: 0,
    today_rmr: 0,
    Age: 30,
  };

  const [settings, setSettings] = useState<CoachingSettings>(initialSettings);
  const [aiPrompt, setAiPrompt] = useState(settings.AICoachSystem);
  const [snackbar, setSnackbar] = useState<{ open: boolean, message: string, severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchSettings = async () => {
        const settingsRef = doc(db, "coachingSettings", currentUser.uid);
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          setSettings(docSnap.data() as CoachingSettings);
        
        } else {
          await setDoc(settingsRef, initialSettings, { merge: true });
          setSettings(initialSettings);
          console.error("No such document!");
        }
    };

    fetchSettings();
    
  }, [currentUser.uid]);

  const handleSelectedChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    setSettings(prev => ({
      ...prev,
      [name]: name === 'calorieDeficit' ? parseInt(value, 10) : value
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setSettings(prev => ({
      ...prev,
      [name]: name === 'calorieDeficit' ? parseInt(value, 10) : value
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    settings.AICoachSystem = aiPrompt
    console.log(settings)
    const settingsRef = doc(db, "coachingSettings", settings.userId);
    try {
      await setDoc(settingsRef, settings, { merge: false });
      setSnackbar({ open: true, message: 'Settings saved successfully!', severity: 'success' });
    } catch (error) {
      console.error("Error saving the document: ", error);
      setSnackbar({ open: true, message: 'Error saving settings', severity: 'error' });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container sx={{mb:5}}>
      <Typography variant="h6">
        {settings.AICoachSystem ? 'Edit' : 'Create'} Coaching Settings
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={1} alignItems="left">

          <Divider>You</Divider>

          <InputLabel id="input_gender">Gender</InputLabel>
          <Select
            value={settings.Gender}
            label="Gender"
            labelId='input_gender'
            name="Gender"
            onChange={handleSelectedChange}
          >
            <MenuItem value={"Fem"}>Fem</MenuItem>
            <MenuItem value={"Male"}>Male</MenuItem>
          </Select>

          <TextField
            label="Height in cm"
            value={settings.height_cm}
            onChange={handleChange}
            fullWidth
            name='height_cm'
            type='number'
          />
          <TextField
            label="Age"
            value={settings.Age}
            onChange={handleChange}
            fullWidth
            name='Age'
            type='number'
          />

          <Divider>Coach</Divider>
          <EditableMDXEditor markdown={settings.AICoachSystem} onChange={setAiPrompt} />
          {/* <MDXEditor 
            key={settings.AICoachSystem}  // Adding a key here to force re-render when the content changes
            markdown={settings.AICoachSystem || "Hi Mark"} 
          className="dark-theme dark-editor mdxeditor-popup-container"
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    {' '}
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <ListsToggle />
                  </>
                )
              }),
              listsPlugin(),
              quotePlugin(),
              headingsPlugin(),
              linkPlugin(),
              directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
              linkDialogPlugin(),
              imagePlugin(),
              tablePlugin(),
              thematicBreakPlugin(),
              frontmatterPlugin(),
              markdownShortcutPlugin()
            ]}
            onChange={setAiPrompt}

          /> */}


          <TextField
            fullWidth
            label="Preferred Workouts"
            variant="outlined"
            name="preferredWorkouts"
            value={settings.preferredWorkouts}
            onChange={handleChange}
            margin="dense"
          />

          <TextField
            fullWidth
            label="Food Restrictions"
            variant="outlined"
            name="foodRestrictions"
            value={settings.foodRestrictions}
            onChange={handleChange}
            margin="dense"
          />

          <Divider>Integrations</Divider>

          <TextField
            fullWidth
            label="Exists Dashboard Key"
            variant="outlined"
            name="ExistsKey"
            value={settings.ExistsKey}
            onChange={handleChange}
            margin="dense"
          />
        </Stack>
      </form>
      <CardActions>
        <Button color='warning' onClick={handleSubmit} type="submit">
          Save
        </Button>
      </CardActions>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose}  severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CoachingSettingsComponent;
