import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createMeal, deleteMeal, getSomeMeals, updateMeal } from '../../functions/nutrition';
import { Meal } from '../../types/nutrition';
import NutritionForm from './nutritionForm';
import theme from '../utils/style/theme';
interface MealItemProps {
  meal: Meal;
  onUpdate: (updatedMeal: Meal) => void; // Callback for meal update
  onDelete: (mealId: string) => void; // Callback for meal deletion
  onCopyToToday: (newMeal: Meal) => void; // Callback for meal copy to today
}


const MealItem: React.FC<MealItemProps> = ({ meal, onUpdate, onDelete, onCopyToToday }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState<{ open: boolean, message: string, severity: 'success' | 'error' }>({ open: false, message: '', severity: 'success' });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onUpdate(meal);
    handleMenuClose();
  };

  const handleDelete = () => {
    setIsDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleCopyToToday = async () => {
    const newMeal = {
      ...meal,
      createdAt: Timestamp.now(),
      id: "", // New ID for the copied meal
    };
    const id = await createMeal(newMeal); // Add the meal to the backend
    if (id) {
      newMeal.id = id;
    }
    onCopyToToday(newMeal);
    setSnackbar({ open: true, message: 'Copied meal to today!', severity: 'success' });
    handleMenuClose();
  };

  const handleDeleteConfirmation = async () => {
    await deleteMeal(meal.id!);
    onDelete(meal.id!);
    setSnackbar({ open: true, message: 'Meal deleted successfully!', severity: 'success' });
    setIsDeleteDialogOpen(false);
  };

  const totalMacros = meal.info.reduce(
    (totals, item) => {
      return {
        calories: totals.calories + item.calories,
        protein: totals.protein + item.protein,
        fat: totals.fat + item.fat,
        carbs: totals.carbs + item.carbs,
      };
    },
    { calories: 0, protein: 0, fat: 0, carbs: 0 }
  );

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box className="meal-container" sx={{ padding: 2 }}>

      <Card className="meal-info">
        <CardHeader
          title={
            <Typography variant="h6" sx={{ mb: 2 }}>
              Nutrition Information
            </Typography>
          }
          action={
            <>
              <IconButton onClick={handleMenuClick} color="primary">
                <MoreVertIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleEdit}>
                  <EditIcon fontSize="small" /> Edit
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <DeleteIcon fontSize="small" color="error" /> Delete
                </MenuItem>
                <MenuItem onClick={handleCopyToToday}>
                  <ContentCopyIcon fontSize="small" color="warning" /> Copy to Today
                </MenuItem>
              </Menu>
            </>
          }
          sx={{ alignItems: 'flex-start', pb: 0 }}
        />

        {meal.image && (
          <CardMedia
            component="img"
            image={meal.image}
            title="Meal"
            style={{
              display: 'block',
              maxWidth: '100%',
              width: isMobile ? '100%' : '40%',
              height: 'auto',
            }}
          />
        )}

        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              <strong>Total Macros:</strong>
            </Typography>
            <Typography variant="body2">
              Calories: {totalMacros.calories} | Protein: {totalMacros.protein}g | Fat: {totalMacros.fat}g | Carbs: {totalMacros.carbs}g
            </Typography>
          </Box>

          {meal.info.map((item, index) => (
            <Box key={item.id || index} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">
                <strong>{item.description}</strong>
              </Typography>
              <Typography variant="body2">
                Calories: {item.calories} | Protein: {item.protein}g | Fat: {item.fat}g | Carbs: {item.carbs}g
              </Typography>
              <Typography variant="body2">
                Key Ingredients: {Array.isArray(item.key_ingredients) ? item.key_ingredients.join(', ') : 'No ingredients available'}
              </Typography>
            </Box>
          ))}

          {meal.notes && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">
                <strong>Notes:</strong>
              </Typography>
              <Typography variant="body2">{meal.notes}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this meal?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="error">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};


const Meals: React.FC = () => {
  const { currentUser } = useAuth();
  const [meals, setMeals] = useState<Meal[]>([]);
  const [selectedMeal, setSelectedMeal] = useState<Meal | undefined>();
  const [lastDoc, setLastDoc] = useState<Meal | undefined>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(moment()); // Use moment for date selection
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const stepperRefs = useRef<Array<HTMLDivElement | null>>([]); // To store stepper element refs for scrolling

  useEffect(() => {
    fetchMeals();
  }, []);

  const fetchMeals = async () => {
    const resp = await getSomeMeals(currentUser.uid, 100);
    setLastDoc(resp.meals[-1])
    setMeals(resp.meals);
  };

  const openEditModal = (meal: Meal) => {
    setSelectedMeal(meal);
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setIsModalOpen(false);
  };

  const handleMealSave = async (updatedMeal: Meal) => {
    if (updatedMeal.id) {
      // Update existing meal
      await updateMeal(updatedMeal.id, updatedMeal);
      setMeals(meals.map(meal => (meal.id === updatedMeal.id ? updatedMeal : meal))); // Update state with the edited meal
    } else {
      // Add new meal
      const newMeal = await createMeal(updatedMeal);
      setMeals([...meals, updatedMeal]); // Add new meal to the state
    }
    closeEditModal();
  };

  const handleMealDelete = (mealId: string) => {
    setMeals(meals.filter(meal => meal.id !== mealId)); // Remove the deleted meal from the state
  };

  const handleCopyMealToToday = (newMeal: Meal) => {
    setMeals([...meals, newMeal]); // Add the copied meal to the state
  };

  const mealsByDate = meals.reduce<Record<string, Meal[]>>((acc, meal) => {
    if (meal.createdAt) {
      const dateStr = moment(meal.createdAt.toDate()).format('LL');
      acc[dateStr] = acc[dateStr] || [];
      acc[dateStr].push(meal);
    }
    return acc;
  }, {});

  // Sort the meal dates from large (most recent) to small (oldest)
  const mealDates = Object.keys(mealsByDate).sort((a, b) => moment(b).diff(moment(a)));

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    const index = mealDates.findIndex((d) => moment(d).isSame(date, 'day'));
    if (index >= 0) {
      setSelectedDateIndex(index);
    }
  };

  const scrollToStep = (index: number) => {
    setSelectedDateIndex(index);
    stepperRefs.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <>
      <Box>
        {/* Sticky top date picker and "Go to Today" button */}
        <Box sx={{ position: 'sticky', top: 0, bgcolor: 'black', zIndex: 110, padding: 2 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Pick a Date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
          <Button
            onClick={() => handleDateChange(moment())}
            color="primary"
            sx={{ mb: 2 }}
          >
            Go to Today
          </Button>
        </Box>

        {/* Stepper to navigate through meal dates */}
        <Stepper activeStep={selectedDateIndex} orientation="vertical" nonLinear>
          {mealDates.map((date, index) => (
            <Step key={`${date}-${index}`} >
              <StepLabel
                onClick={() => scrollToStep(index)}
                sx={{ cursor: 'pointer' }}
              >
                {/* Daily macros summary */}
                <Box>
                  <Typography color='success' component="span" sx={{ fontWeight: 'bold' }}>
                    {moment(mealsByDate[date][0].createdAt?.toDate()).format('dddd YYYY-MM-DD')}
                  </Typography>
                  <Stack
                    direction={{ xs: 'row', sm: 'row' }}
                    spacing={1}
                    sx={{ alignItems: { xs: 'flex-start', sm: 'center' }, flexWrap: 'wrap' }}
                  >
                    <AvatarGroup max={4}>
                      {mealsByDate[date].map((meal, index) => (
                        meal.image ? (
                          <Avatar alt={meal.notes} src={meal.image + `?width=200`} />
                        ) : null
                      ))}
                    </AvatarGroup>
                    <Typography >
                      Cals: {mealsByDate[date].reduce(
                        (acc, meal) => acc + meal.info.reduce((sum, info) => sum + info.calories, 0),
                        0
                      )}g
                      Protein: {mealsByDate[date].reduce(
                        (acc, meal) => acc + meal.info.reduce((sum, info) => sum + info.protein, 0),
                        0
                      )}g
                      Carbs: {mealsByDate[date].reduce(
                        (acc, meal) => acc + meal.info.reduce((sum, info) => sum + info.carbs, 0),
                        0
                      )}g
                      Fats: {mealsByDate[date].reduce(
                        (acc, meal) => acc + meal.info.reduce((sum, info) => sum + info.fat, 0),
                        0
                      )}g
                    </Typography>
                  </Stack>
                </Box>
              </StepLabel>
              <StepContent>
                <List>
                  <Card ref={(el) => (stepperRefs.current[index] = el)} >
                    {mealsByDate[date].map((meal) => (
                      <React.Fragment key={meal.id} >
                        <MealItem
                          meal={meal}
                          onUpdate={openEditModal}
                          onDelete={handleMealDelete}
                          onCopyToToday={handleCopyMealToToday}
                        />
                        <Divider />
                      </React.Fragment>
                    ))}
                  </Card>

                </List>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {selectedMeal && (
          <Dialog open={isModalOpen} onClose={closeEditModal} fullWidth maxWidth="md">
            <DialogTitle>{selectedMeal.id ? 'Edit Meal' : 'Add Meal'}</DialogTitle>
            <DialogContent>
              <NutritionForm meal={selectedMeal} onSave={handleMealSave} onClose={closeEditModal} />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export { MealItem, Meals };

