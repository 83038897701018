import { Box, CircularProgress, Container, Divider } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Workout } from '../../types/fitness';
import { ExistAttribute } from '../../types/utils';

import { getTodayWorkout } from "../../functions/fitness";
import { getTodayMeals } from "../../functions/nutrition";
import { Meal } from "../../types/nutrition";
import { PageAppBar } from "../pages/navbar";
import { PlanPanel } from "../planner/planner";
import ExistData from "../utils/exists";





const DailyData: React.FC = () => {
    const [allData] = useState<ExistAttribute[]>([]);
    const [loading,] = useState(false);
    const { currentUser } = useAuth();





    return (
        <>
       <Container
       >
               <PageAppBar pageTitle="Daily" />
            {loading ? (
                 <Box>
                <CircularProgress />
                </Box>
            ) : (

                <Box>
                    <Box>
                            <ExistData />
                    </Box>
                    <Divider></Divider>
                    <Box>
                            <PlanPanel />
                        </Box>
                       
                </Box>
            )}
</Container>
        </>
    );
};

export default DailyData;


