import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext'; // Ensure this is set up for authentication
import { getPaginatedTimelineEvents } from '../../functions/timeline';
import { TimelineEvent } from '../../types/timeline';
import { PageAppBar } from "../pages/navbar";
import { TimelineEventComponent, TimelineEventForm } from './event'; // Import the journal entry component

/// styling
import { Box, Button, Dialog, DialogActions, ImageList, ImageListItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import WeatherWidget from '../utils/weather';

const Timeline: React.FC = () => {
  const { currentUser } = useAuth();
  const [entries, setEntries] = useState<TimelineEvent[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [lastDoc, setLastDoc] = useState<TimelineEvent | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const loading = useRef(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (currentUser) {
      loadInitialEntries();
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (entries.length > 0) {
      const lastEntry = entries[entries.length - 1];
      setLastDoc(lastEntry);
    }
  }, [entries]);


  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !loading.current) {
        loadMoreEntries();
      }
    });
    if (bottomRef.current) {
      observer.current.observe(bottomRef.current);
    }
  }, [lastDoc]);

  const loadMoreEntries = useCallback(async () => {
    if (lastDoc && !loading.current) {
      loading.current = true;
      const moreEntries = await getPaginatedTimelineEvents(currentUser.uid, lastDoc);
      if (moreEntries.length > 0) {
        setEntries((prevEntries) => [...prevEntries, ...moreEntries]);
      }
      loading.current = false;
    }
  }, [lastDoc, currentUser]);

  const loadInitialEntries = useCallback(async () => {
    if (!currentUser) {
      console.error("No user logged in!");
      return;
    }
    const fetchedEntries = await getPaginatedTimelineEvents(currentUser.uid, lastDoc);
    setEntries(fetchedEntries);
  }, [currentUser, lastDoc]);

  const handleSaveEntry = (entry: TimelineEvent) => {
    if (entry.id) {
      // Update the entry in the list if it exists
      setEntries((prevEntries) => prevEntries.map(e => e.id === entry.id ? entry : e));
    } else {
      // Add new entry to the list
      setEntries((prevEntries) => [...prevEntries, entry]);
    }
    setModalOpen(false); // Close the modal after saving
  };

  const handleEntryUpdated = (updatedEntry: TimelineEvent) => {
    setEntries((prevEntries) => prevEntries.map(entry => entry.id === updatedEntry.id ? updatedEntry : entry));
  };

  const handleEntryDeleted = async (entryId: string) => {
    const updatedEntries = entries.filter(entry => entry.id !== entryId);
    setEntries(updatedEntries);
  };

  // Function to filter events
  const filteredEvents = entries.filter(event => {
    const matchesSearchTerm = searchTerm ? event.title.toLowerCase().includes(searchTerm.toLowerCase()) || ( event.caption || "").toLowerCase().includes(searchTerm.toLowerCase()) : true;
    const matchesTags = tagFilter.length > 0 ? tagFilter.every(tag => event.tags.includes(tag)) : true;

    return matchesSearchTerm && matchesTags;
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ pb: 7, maxWidth:"100%" }}>
      <PageAppBar pageTitle="Timeline" />
      <ImageList cols={isMobile ? 1 : 3} >
      <ImageListItem key="header" cols={isMobile ? 1 : 3}>
        <TextField
          fullWidth
          multiline
          placeholder="Search by title or caption..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          margin="normal"
        />
        <WeatherWidget />
      </ImageListItem>
      {filteredEvents.map(entry => (
        <ImageListItem key={entry.id} cols={isMobile ? 1 : 1}>
          <TimelineEventComponent
            entry={entry}
            onEntryUpdated={handleSaveEntry}
            onEntryDeleted={handleEntryDeleted}
          />
        </ImageListItem>
      ))}
    </ImageList>
      <div ref={bottomRef}></div>
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md" fullScreen>
        <TimelineEventForm userId={currentUser ? currentUser.uid : ''} onSave={handleSaveEntry} />
        <DialogActions>
          <Button color="error" onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Timeline;
