import { MDXEditor, listsPlugin, quotePlugin, headingsPlugin, linkPlugin, directivesPlugin, AdmonitionDirectiveDescriptor, linkDialogPlugin, imagePlugin, tablePlugin, thematicBreakPlugin, frontmatterPlugin, diffSourcePlugin, markdownShortcutPlugin, BoldItalicUnderlineToggles, ListsToggle, UndoRedo, toolbarPlugin } from '@mdxeditor/editor';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { processText } from '../../functions/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';




interface ReadOnlyMDXEditorProps {
  markdown: string;
  textLabel?: string;
}

const ReadOnlyMDXEditor: React.FC<ReadOnlyMDXEditorProps> = ({ markdown, textLabel }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  return (
    <Box>
 
     {isExpanded && 
      <Box
      sx={{
        border: '1px solid #ccc',  // Define the border color and thickness
        borderRadius: '4px',       // Optional: Add rounded corners
      }}
    
    >
    <MDXEditor
      markdown={markdown}
      key={markdown}
      className={"dark-theme dark-editor"}
      plugins={[
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
        linkDialogPlugin(),
        imagePlugin(),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        diffSourcePlugin({
          viewMode: 'rich-text',
          readOnlyDiff: true,
        }),
        markdownShortcutPlugin(),
      ]}
      readOnly
    /> </Box>
  }
         <IconButton onClick={toggleExpansion} aria-label="...">
         {isExpanded ? <ExpandLessIcon/> : <Typography color='secondary' variant='body2'>
         { (textLabel || '...')}

         </Typography>}       </IconButton>
    </Box>
  );
};

interface EditableMDXEditorProps {
    markdown: string;
    onChange: (markdown: string) => void;
  }
  
  const EditableMDXEditor: React.FC<EditableMDXEditorProps> = ({ markdown, onChange }) => {
    return (

        <Box
        sx={{
          border: '1px solid',  // Define the border color and thickness
          borderRadius: '4px',       // Optional: Add rounded corners
        }}
      >
      <MDXEditor
      plugins={[
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {' '}
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
            </>
          )
        }),
        listsPlugin(),
        quotePlugin(),
        headingsPlugin(),
        linkPlugin(),
        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
        linkDialogPlugin(),
        imagePlugin(),
        tablePlugin(),
        thematicBreakPlugin(),
        frontmatterPlugin(),
        markdownShortcutPlugin(),
        diffSourcePlugin({
            viewMode: 'rich-text',
            readOnlyDiff: false, // Allow diff edits if applicable
          }),
      ]}
        markdown={markdown}
        className={ "dark-theme dark-editor"}
       key={markdown}
        onChange={onChange}
      />
      </Box>
    );
  };

export  {ReadOnlyMDXEditor, EditableMDXEditor};
