import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonGroup, IconButton, Paper, Stack, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import React, { useEffect, useState } from 'react';
import { updateLoggedWorkout } from '../../functions/fitness';
import { LoggedWorkout, Workout, WorkoutExercise } from '../../types/fitness';

interface Props {
    workout: Workout;
    handleClose: () => void;
}

const WorkoutPlayer: React.FC<Props> = ({ workout, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [workoutState, setWorkoutState] = useState<Workout>(workout);
    let wakeLock: WakeLockSentinel | null = null;

    useEffect(() => {
        // Request the wake lock
        const requestWakeLock = async () => {
            try {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('Screen Wake Lock is active');
            } catch (err) {
                console.error(err);
            }
        };

        // Request wake lock on mount
        requestWakeLock();

        // Release the wake lock on unmount
        return () => {
            if (wakeLock) {
                wakeLock.release();
                console.log('Screen Wake Lock released');
            }
        };
    }, []);

    const handleNext = () => {
        saveWorkout(workoutState);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const deleteExercise = (index: number) => {
        const updatedExercises = workoutState.exercises.filter((_, i) => i !== index);
        setWorkoutState({ ...workoutState, exercises: updatedExercises });
    };

    const handleExerciseChange = (index: number, field: keyof WorkoutExercise, value: string) => {
        const updatedExercises = workoutState.exercises.map((exercise, i) => {
            if (i === index) {
                return { ...exercise, [field]: value };
            }
            return exercise;
        });
        setWorkoutState({ ...workoutState, exercises: updatedExercises });
    };

    const addExercise = () => {
        const newExercise: WorkoutExercise = {
            workoutId: workoutState.id,
            id: `exercise-${workoutState.exercises.length}`,
            exercise: '',
            reps: 0,
            sets: 0,
            weight: 0,
            duration: 0,
            currentSets: 0,
            order: workoutState.exercises.length
        };
        setWorkoutState({ ...workoutState, exercises: [...workoutState.exercises, newExercise] });
    };

    const handleSetIncrement = (index: number) => {
        const updatedExercises = workoutState.exercises.map((exercise, i) => {
            if (i === index) {
                return { ...exercise, currentSets: exercise.currentSets ? exercise.currentSets + 1 : 1 };
            }
            return exercise;
        });
        setWorkoutState({ ...workoutState, exercises: updatedExercises });
    };

    const saveWorkout = (workout: Workout) => {
        try {
            updateLoggedWorkout(workout.id, workout as Partial<LoggedWorkout>);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSetDecrement = (index: number) => {
        const updatedExercises = workoutState.exercises.map((exercise, i) => {
            if (i === index) {
                return { ...exercise, currentSets: exercise.currentSets ? exercise.currentSets - 1 : 0 };
            }
            return exercise;
        });
        setWorkoutState({ ...workoutState, exercises: updatedExercises });
    };

    return (
        <Box sx={{ maxWidth: '100vw', padding: 2 }}>
            <IconButton onClick={handleClose} aria-label="delete">
                <CloseIcon />
            </IconButton>
            {workoutState.id}
            <Stepper activeStep={activeStep} orientation="vertical">
                {workoutState.exercises.map((exercise, index) => (
                    <Step key={exercise.id}>
                        <StepLabel>{exercise.exercise || `Exercise ${index + 1}`}</StepLabel>
                        <StepContent>
                            <TextField
                                label="Exercise Name"
                                value={exercise.exercise}
                                onChange={(e) => handleExerciseChange(index, 'exercise', e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                label="Reps"
                                type="number"
                                value={exercise.reps}
                                onChange={(e) => handleExerciseChange(index, 'reps', e.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                label="Sets"
                                type="number"
                                value={exercise.sets}
                                onChange={(e) => handleExerciseChange(index, 'sets', e.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />

                            <TextField
                                label="Weight"
                                type="number"
                                value={exercise.weight}
                                onChange={(e) => handleExerciseChange(index, 'weight', e.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                label="Duration (seconds)"
                                type="number"
                                value={exercise.duration}
                                onChange={(e) => handleExerciseChange(index, 'duration', e.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />

                            <ButtonGroup>
                                <Button
                                color='error'
                                    onClick={() => deleteExercise(index)}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Delete Exercise
                                </Button>
                            </ButtonGroup>

                            <Stack direction="column" spacing={2} sx={{ width: '100%', mt: 2 }}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    startIcon={<ArrowUpwardIcon />}
                                    onClick={() => handleSetIncrement(index)}
                                    sx={{ height: '48px' }}
                                >
                                    Increment Set
                                </Button>
                                <Typography variant="h5" textAlign="center">
                                    Current Sets: {exercise.currentSets || 0}
                                </Typography>
                                <Button
                                    fullWidth
                                    color="primary"
                                    startIcon={<ArrowDownwardIcon />}
                                    onClick={() => handleSetDecrement(index)}
                                    disabled={exercise.currentSets === 0}
                                    sx={{ height: '48px' }}
                                >
                                    Decrement Set
                                </Button>
                            </Stack>

                            <Box sx={{ position: 'fixed', bottom: 30, left: 16, zIndex: 1100 }}>
                                <Fab
                                    variant="extended"
                                    disabled={index === 0}
                                    onClick={handleBack}
                                    size="small"
                                    color="primary"
                                >
                                    <ArrowBackIosIcon sx={{ mr: 1 }} />
                                    Back
                                </Fab>
                            </Box>

                            <Box sx={{ position: 'fixed', bottom: 30, right: 16, zIndex: 1100 }}>
                                <Fab variant="extended" size="medium" onClick={handleNext} color="primary">
                                    <ArrowForwardIosIcon sx={{ mr: 1 }} />
                                    {index === workoutState.exercises.length - 1 ? 'Finish' : 'Next'}
                                </Fab>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === workoutState.exercises.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Button
                        color="primary"
                        onClick={addExercise}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Add Exercise
                    </Button>
                    <Typography>All steps completed - you're finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button>
                    <Button onClick={handleClose} sx={{ mt: 1, mr: 1 }}>
                        Close
                    </Button>
                </Paper>
            )}
        </Box>
    );
};

export default WorkoutPlayer;
