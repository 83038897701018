// WorkoutListView.tsx
import DeleteIcon from '@mui/icons-material/Delete';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { Alert, Box, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, List, ListItem, ListItemText, Snackbar, TextField, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createWorkout, deleteWorkout, getGeminiWorkout, getWorkouts, logWorkout, updateLoggedWorkout, updateWorkout } from '../../functions/fitness';
import { LoggedWorkout, Workout, WorkoutExercise } from '../../types/fitness';
import { ExerciseEditor } from './exercise';
import WorkoutPlayer from './play_workout';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';


interface WorkoutProps {
  programId: string;
}



const WorkoutListView: React.FC<WorkoutProps> = ({ programId }) => {
  const { currentUser } = useAuth();
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [currentWorkout, setCurrentWorkout] = useState<Workout>({
    id: "new",
    programId: programId,
    title: "",
    description: "",
    createdBy: currentUser.uid,
    createdAt: Timestamp.now(),
    exercises: []
  });
  const [openForm, setOpenForm] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [workoutToDelete, setWorkoutToDelete] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [loggingConfirmationDialogOpen, setLoggingConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    loadWorkouts();
  }, [currentUser.uid]);

  const loadWorkouts = async () => {
    const loadedWorkouts = await getWorkouts(programId);
    setWorkouts(loadedWorkouts);
  };

  const handleOpenForm = (workout?: Workout | null) => {
    if (workout) {
      setCurrentWorkout(workout);
    } else {
      setCurrentWorkout({
        id: "new",
        programId: programId,
        title: "",
        description: "",
        createdBy: currentUser.uid,
        createdAt: Timestamp.now(),
        exercises: []
      });
    }
    setOpenForm(true);
  };

  const handleOpenLog = async (workout?: Workout) => {
    if (!workout) {
      console.error("No workout available to log.");
      return;
    }
    setCurrentWorkout(workout);
    setLoggingConfirmationDialogOpen(true);
  };

  const confirmLogWorkout = async () => {
    try {
      const loggedW = await logWorkout({
        workout: { ...currentWorkout, id: "", createdAt: Timestamp.now() },
        userId: currentUser.uid
      });

      if (loggedW) {
        await updateLoggedWorkout(loggedW.workout.id, loggedW);
        setOpenLog(true);
        setSnackbarMessage('Workout logged successfully');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Unable to create a logged workout');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Error logging workout');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      setLoggingConfirmationDialogOpen(false);
    }
  };

  const handleDelete = (workoutId: string) => {
    setWorkoutToDelete(workoutId);
    setConfirmationDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (workoutToDelete) {
      try {
        await deleteWorkout(workoutToDelete);
        setWorkouts(workouts.filter(c => c.id !== workoutToDelete));
        loadWorkouts();
        setSnackbarMessage('Workout deleted successfully');
        setSnackbarSeverity('success');
      } catch (error) {
        setSnackbarMessage('Error deleting workout');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setWorkoutToDelete(null);
        setConfirmationDialogOpen(false);
      }
    }
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    loadWorkouts(); // Reload the workouts to reflect any changes
  };

  const handlePlayClose = () => {
    setOpenLog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setWorkoutToDelete(null);
  };

  const handleCloseLoggingDialog = () => {
    setLoggingConfirmationDialogOpen(false);
  };

  return (
    <Container>
      <List dense>
        {workouts.map(workout => (
          <ListItem key={workout.id} divider style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <ListItemText primary={workout.title} primaryTypographyProps={{ variant: 'body1' }} />
            <ListItemText secondary={workout.description} secondaryTypographyProps={{ variant: 'body2' }} />
            <Box style={{ display: 'flex', gap: '10px', marginTop: '0.5rem' }}>
              <Button onClick={() => handleOpenForm(workout)}>Edit</Button>
              <Button color="warning" onClick={() => handleOpenLog(workout)}>Play</Button>
              <Button color="error" onClick={() => handleDelete(workout.id)}>Delete</Button>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button onClick={() => handleOpenForm()}>
        Add New Workout
      </Button>
      {openForm && <WorkoutForm open={openForm} log={false} onClose={handleCloseForm} programWorkout={currentWorkout} />}
      {openLog && currentWorkout &&
        <Dialog open={openLog} onClose={handlePlayClose} fullScreen maxWidth="md">
          <DialogTitle>{currentWorkout.title} {currentWorkout.id}</DialogTitle>
          <DialogContent>
            <WorkoutPlayer workout={currentWorkout} handleClose={handlePlayClose} />
          </DialogContent>
        </Dialog>
      }

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmationDialogOpen} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this workout?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Logging Workout */}
      <Dialog open={loggingConfirmationDialogOpen} onClose={handleCloseLoggingDialog}>
        <DialogTitle>Confirm Logging</DialogTitle>
        <DialogContent>This action will add an entry to your log. Do you want to continue?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoggingDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmLogWorkout} color="primary">
            Log Workout
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};


interface WorkoutFormProps {
  programWorkout: Workout;
  open: boolean;
  onClose: () => void;
}




interface WorkoutFormProps {
  programWorkout: Workout;
  log : boolean;
  open: boolean;
  onClose: () => void;
}

// Assuming getGeminiWorkout is imported
// import { getGeminiWorkout } from 'path-to-your-getGeminiWorkout-function';
const WorkoutForm: React.FC<WorkoutFormProps> = ({ programWorkout, open, log, onClose }) => {
  const [workout, setWorkout] = useState<Workout>(programWorkout);
  const [exercises, setExercises] = useState<WorkoutExercise[]>(programWorkout.exercises);
  const [workoutDate, setWorkoutDate] =  React.useState<Timestamp>(workout?.createdAt);
  const [loading, setLoading] = useState(false);
  const [isLog, setLog] = useState(log)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [exerciseToRemove, setExerciseToRemove] = useState<string | null>(null);
  const { currentUser } = useAuth();
  useEffect(() => {
    if (programWorkout) {
      setWorkout(programWorkout);
    }
  }, [programWorkout]);

  const handleExercisesChange = (updatedExercises: WorkoutExercise[]) => {
    setExercises(updatedExercises);
  };

  const handleWorkoutChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setWorkout({ ...workout, [name]: value });
  };

  const removeExercise = (id: string) => {
    setExerciseToRemove(id);
    setConfirmationDialogOpen(true);
  };

  const confirmRemoveExercise = () => {
    if (exerciseToRemove) {
      setWorkout((prevState) => ({
        ...prevState,
        exercises: prevState.exercises.filter((ex) => ex.id !== exerciseToRemove),
      }));
      setSnackbarMessage('Exercise removed successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setExerciseToRemove(null);
    }
    setConfirmationDialogOpen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    workout.exercises = exercises;
    workout.createdAt = workoutDate;
    if (isLog){
      try {
        if (workout.id) {
      
          updateLoggedWorkout(workout.id, workout as Partial<LoggedWorkout>);
          setSnackbarMessage('Workout log created successfully');
        }
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onClose();
      } catch (error) {
        setSnackbarMessage('Error saving workout');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
   
       
      }
    }else{
      try {
        if (workout.id !== 'new') {
          await updateWorkout(workout.id, workout);
          setSnackbarMessage('Workout updated successfully');
        } else {
          await createWorkout(workout);
          setSnackbarMessage('Workout created successfully');
        }
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onClose();
      } catch (error) {
        setSnackbarMessage('Error saving workout');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }

 
  };

  const handleGetGeminiWorkout = async () => {
    setLoading(true);
    try {
      const updatedWorkout = await getGeminiWorkout(workout);
      setWorkout(updatedWorkout);
      setExercises(updatedWorkout.exercises);
      setSnackbarMessage('AI-generated workout loaded successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage('Error generating workout');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const dateForPicker = workout.createdAt ? dayjs(workout.createdAt.toDate()) : null;

  const handleDateChange = (newValue: Dayjs | null) => {
    const timestamp = newValue ? Timestamp.fromDate(newValue.toDate()) : workoutDate;
    setWorkoutDate(timestamp);
  };


  return (
    <Dialog open={open} sx={{ display: 'block', pb: 5, pt: 5 }} onClose={onClose} fullScreen>
      <Container>
        <Typography variant="h6">Editing | {workout.title}</Typography>
        <Box component="form" onSubmit={handleSubmit} onReset={onClose} sx={{ mt: 1, p: 2 }}>
          <Container sx={{ p: 2 }}>
            {['title', 'description'].map((field) => (
              <TextField
                key={field}
                fullWidth
                label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                name={field}
                value={(workout as any)[field]}
                onChange={handleWorkoutChange}
                margin="normal"
                type={'text'}
                multiline={field === 'description'}
                rows={field === 'description' ? 4 : 1}
              />
            ))}
{log &&

<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker label= "Log Date" value={dateForPicker} onChange={handleDateChange} />
</LocalizationProvider>

}
            {loading && (
              <Typography sx={{ mt: 2, mb: 2 }}>
                <LinearProgress color="success" />
              </Typography>
            )}

            <Button
              onClick={handleGetGeminiWorkout}
              color="warning"
              startIcon={<PrecisionManufacturingIcon />}
            >
              AI Workout
            </Button>
          </Container>
          <Container>
            <ExerciseEditor
              workoutId={workout ? workout.id : 'new'}
              initial_exercises={workout.exercises}
              onExercisesChange={handleExercisesChange}
            />
          </Container>
          <Container>
            <Typography variant="h6" sx={{ mt: 2 }}>Exercises</Typography>
            {workout.exercises.map((ex, index) => (
              <Box key={ex.id} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography sx={{ width: '100%' }}>
                  {index + 1}. {ex.exercise} - Sets: {ex.sets}, Reps: {ex.reps}, Weight: {ex.weight}lbs, Duration: {ex.duration}s
                </Typography>
                <IconButton onClick={() => removeExercise(ex.id)} aria-label="delete">
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            ))}
          </Container>

          <Button type="submit"  color='warning' sx={{ mt: 2 }}>Submit Workout</Button>
          <Button type="reset" color="error" sx={{ mt: 2 }}>Close</Button>
        </Box>
      </Container>

      <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
        <DialogTitle>Confirm Exercise Removal</DialogTitle>
        <DialogContent>Are you sure you want to remove this exercise?</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRemoveExercise} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};



interface WorkoutCardProps {
  workout: Workout;
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workout }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleExerciseClick = (workout: Workout) => {

    setSelectedWorkout(workout);
    setModalOpen(true);
  };



  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {workout.title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Created at: {new Date(workout.createdAt.toDate()).toLocaleDateString()}
        </Typography>
        <Typography variant="body2" paragraph>
          {workout.description}
        </Typography>
        <Divider />
        <Typography variant="h6" gutterBottom>
          Exercises
        </Typography>
        <List>
          {workout.exercises.map((exercise) => (
            <ListItem key={exercise.id}>
              <ListItemText
                primary={exercise.exercise}
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Reps: {exercise.reps}, Sets: {exercise.sets}, Weight: {exercise.weight}lbs, Duration: {exercise.duration}s, Order: {exercise.order}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>

  );
};



export { WorkoutCard, WorkoutForm, WorkoutListView };

