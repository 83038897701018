import { Timestamp } from '@firebase/firestore';
import AddTaskIcon from '@mui/icons-material/AddTask';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SpaIcon from '@mui/icons-material/Spa';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import {
  AppBar,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Slide,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; // Adjust the import path as necessary
import { logWorkout } from '../../functions/fitness';
import { Workout } from '../../types/fitness';
import { Meal } from '../../types/nutrition';
import { Task } from '../../types/planner';
import NutritionForm from '../fitness/nutritionForm';
import WorkoutPlayer from '../fitness/play_workout';
import { TaskForm } from '../planner/tasks';
import { TimelineEventForm } from '../timeline/event';

interface NavbarProps {
  value: number;
  setValue: (newValue: number) => void;
}

const Navbar: React.FC<NavbarProps> = ({ value, setValue }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  // Function to determine if the route is the current one
  const isRouteActive = (route: string) => location.pathname === route;

  return (
    <Paper sx={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0 }} elevation={3}>
      {currentUser ? (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels // Optional: if you want labels to always show
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeIcon />}
            component={Link}
            to="/home"
            style={{ color: isRouteActive('/') ? 'primary' : 'inherit' }}
          />
          <BottomNavigationAction
            label="Health"
            icon={<SpaIcon />}
            component={Link}
            to="/fitness"
            style={{ color: isRouteActive('/fitness') ? 'primary' : 'inherit' }}
          />
          <BottomNavigationAction
            label="Games"
            icon={<VideogameAssetIcon />}
            component={Link}
            to="/games"
            style={{ color: isRouteActive('/games') ? 'primary' : 'inherit' }}
          />
        </BottomNavigation>
      ) : (
       <></>
      )}
    </Paper>
  );
};


const ScrollTop: React.FC = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Box sx={{ position: 'fixed', bottom: 60, left: 16, zIndex: 1100 }} style={{ display: trigger ? 'flex' : 'none' }}>
      <Fab color="secondary" size="small" onClick={handleClick} aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </Box>
  );
}

const HideOnScroll: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const trigger = useScrollTrigger({
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface AppBarProps {
  pageTitle: string;
}

const PageAppBar: React.FC<AppBarProps> = ({ pageTitle }) => {
  const { currentUser } = useAuth();


  return (
    <Container sx={{ mb: 10 }}>
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar>
            <Typography color={"warning"} variant="h6" noWrap sx={{ flexGrow: 1 }}>
              {pageTitle}
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton component={Link} to="/user" sx={{ p: 0 }}>
                <Avatar alt="user" src={currentUser.photoURL} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <ScrollTop />
      <CTRLSpeedail />
    </Container>


  );
}


function CTRLSpeedail() {
  const { currentUser } = useAuth();
  const [isMealModalOpen, setIsMealModalOpen] = useState<boolean>(false);
  const [isWorkoutModalOpen, setIsWorkoutModalOpen] = useState<boolean>(false);
  const [modalTLOpen, setModalTLOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [newWorkout, setNewWorkout] = useState<Workout>({
    id: "new",
    programId: "NOPROGRAM",
    title: "Free Workout",
    description: "",
    createdBy: currentUser.uid,
    createdAt: Timestamp.now(),
    exercises: [] // Directly use the array of Exercise objects
  } as Workout);
  const [value, setValue] = React.useState(0);

  const handleAddNewMeal = (): Meal => {
    const newMeal: Meal = {
      id: undefined,
      image: "",
      notes: "",
      info: [],
      userId: currentUser.uid,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };


    return newMeal
  };

  const handleAddNewWorkoutlog = (): Workout => {

    const w = {
      id: "new",
      programId: "NOPROGRAM",
      title: "Free Workout",
      description: "",
      createdBy: currentUser.uid,
      createdAt: Timestamp.now(),
      exercises: [] // Directly use the array of Exercise objects
    }
    logWorkout({ workout: w, userId: currentUser.uid })
    return w
  }


  const openMealModel = () => {
    setIsMealModalOpen(true);
  }

  const closeMealModal = () => {
    setIsMealModalOpen(false);
  };


  const openTaskModel = () => {
    setIsTaskModalOpen(true);
  }

  const closeTaskModal = () => {
    setIsTaskModalOpen(false);
  };


  const openWorkoutModel = () => {
    const w = handleAddNewWorkoutlog()
    setNewWorkout(w)
    setIsWorkoutModalOpen(true);
  }

  const closeWorkoutModal = () => {
    setIsWorkoutModalOpen(false);
  };


  const openTLModel = () => {
    setModalTLOpen(true);
  };

  const handleTLCloseModal = () => {

    setModalTLOpen(false);
  };



  const actions = [
    { icon: <DinnerDiningIcon color='error' />, name: 'Add Meal', click: openMealModel },
    { icon: <FitnessCenterIcon color='error' />, name: 'Workout', click: openWorkoutModel },
    { icon: <DynamicFeedIcon color='error' />, name: 'Post', click: openTLModel },
    { icon: <AddTaskIcon color='error' />, name: 'Create Task', click: openTaskModel },
  ];


  function handleSaveTask(task: Task): void {
    setIsTaskModalOpen(false)
  }

  return (
    <Box>
      <SpeedDial
        color='warning'
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 55, right: 16 }}

        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            // role="menuitem"

            tooltipOpen
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.click}

          />
        ))}
      </SpeedDial>
      <Dialog open={isMealModalOpen} onClose={closeMealModal} fullWidth fullScreen maxWidth="md" >
        <DialogTitle>Edit Meal</DialogTitle>
        <DialogContent>
          <NutritionForm meal={handleAddNewMeal()} onSave={closeMealModal} onClose={closeMealModal} />
        </DialogContent>
      </Dialog>


      <Dialog open={isWorkoutModalOpen} onClose={closeWorkoutModal} fullScreen maxWidth="md">
        <DialogTitle>Free Play</DialogTitle>
        <DialogContent>
          <WorkoutPlayer workout={newWorkout} handleClose={closeWorkoutModal} />
        </DialogContent>
      </Dialog>

      <Dialog open={modalTLOpen} onClose={handleTLCloseModal} fullWidth maxWidth="md" fullScreen>
        <TimelineEventForm userId={currentUser ? currentUser.uid : ''} onSave={handleTLCloseModal} />
        <DialogActions>
          <Button color="error" onClick={handleTLCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isTaskModalOpen} onClose={closeTaskModal} fullWidth maxWidth="md" fullScreen>
        <DialogContent>
          <TaskForm onSave={handleSaveTask} />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={closeTaskModal}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>

  )
}
export { Navbar, PageAppBar };
