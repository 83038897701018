import TimerIcon from '@mui/icons-material/Timer';
import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface TimerProps {
    initialSeconds: number;  // Total seconds to start with
}

const TimerDisplay: React.FC<TimerProps> = ({ initialSeconds }) => {
    const [seconds, setSeconds] = useState(initialSeconds);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (isActive) {
            intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000); // Update every second
            if (seconds == 0){
                setIsActive(false)
            }
        }

        return () => clearInterval(intervalId); // Cleanup interval on component unmount or isActive change
    }, [isActive]); // Effect runs when isActive changes

    // Function to convert seconds to HH:MM:SS format
    const formatTime = (totalSeconds: number) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const paddedHours = String(hours).padStart(2, '0');
        const paddedMinutes = String(minutes).padStart(2, '0');
        const paddedSeconds = String(seconds).padStart(2, '0');

        return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
    };

    return (
        <Box textAlign="center">
            <IconButton onClick={() => setIsActive(!isActive)} >
                <TimerIcon  color='secondary' />  {formatTime(seconds)}
              </IconButton>

        </Box>
    );
};

export default TimerDisplay;
