import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Card, CardActions, CardContent, Collapse, Dialog, DialogContent, DialogTitle, Divider, Fab, IconButton, List, ListItem, ListItemText, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createCycle, deleteCycle, getCycles, updateCycle } from '../../functions/cycles';
import { Cycle, CycleInstance } from '../../types/utils';
import InstanceForm from './instance';



interface CycleFormProps {
  cycle: Cycle | null;
  onSave: (updatedCycle: Cycle) => void;
}
const CycleForm: React.FC<CycleFormProps> = ({ cycle, onSave }) => {
  const { currentUser } = useAuth();
  const [instances, setInstances] = useState<CycleInstance[]>(cycle?.instances|| [])
  const [cycleState, setCycleState] = useState<Cycle>(cycle || {
    name: '',
    id: '',
    createdBy: currentUser.uid,
    description: '',
    ai_ok: false,
    duration_days: 0,
    instances: [],
    createdAt: Timestamp.now()
  });


  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof Cycle) => {
   if (field != "instances"){ setCycleState({
      ...cycleState,
      [field]: e.target.value
    });}
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const newCycle: Cycle = {
     id: cycleState.id || "",
     createdBy: cycleState.createdBy ||currentUser.uid,
     description: cycleState.description || "",
     duration_days: cycleState.duration_days || 0,
     name : cycleState.name || "",
     ai_ok: cycleState.ai_ok || false,
     instances: instances || [],
     createdAt: cycleState.createdAt || Timestamp.now()
    
    };
    if (cycle) {
      await updateCycle(cycle.id, newCycle);
    } else {
      await createCycle(newCycle);
    }
  
    onSave(newCycle);
  };


  const handleInstanceChange = (inst: CycleInstance[]) => {
    setInstances(inst);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={cycleState.name}
        onChange={e => handleInputChange(e, 'name')}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        value={cycleState.description}
        onChange={e => handleInputChange(e, 'description')}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Duration (Days)"
        type="number"
        value={cycleState.duration_days}
        onChange={e => handleInputChange(e, 'duration_days')}
        fullWidth
        margin="normal"
      />
      <InstanceForm initInstances={instances} onInstanceChange={handleInstanceChange} />

      <Button type="submit" color="warning">
        Save Cycle
      </Button>
    </form>
  );
};
interface ExtendedCycle extends Cycle {
  id: string;
  nextInstanceDate?: Date;
}

const CycleList: React.FC = () => {
  const { currentUser } = useAuth();
  const [cycles, setCycles] = useState<ExtendedCycle[]>([]);
  const [open, setOpen] = useState<string>('');
  const [selectedCycle, setSelectedCycle] = useState<Cycle | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchCycles = async () => {
      const fetchedCycles = await getCycles(currentUser.uid);
      setCycles(fetchedCycles);
    };
    fetchCycles();
  }, [currentUser]);

  const openModal = (cycle: Cycle) => {
    setSelectedCycle(cycle);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCycle(null);
  };

  const handleToggle = (id: string) => {
    setOpen(open === id ? '' : id);
  };

  const handleNewCycle = () => {
    setSelectedCycle(null);
    setIsModalOpen(true);
  };

  const handleDeleteCycle = async (cycleId: string) => {
    await deleteCycle(cycleId);
    setCycles(cycles.filter(c => c.id !== cycleId));
  };

  const handleSaveCycle = (updatedCycle: Cycle) => {
    closeModal();
  };

  const calculateNextInstanceDateString = (cycle: Cycle): string => {
    if (cycle.instances.length === 0) return "No Instance";
    const lastInstanceDate = new Date(cycle.instances[cycle.instances.length - 1].instanceDate.toDate());
    const nextInstanceDate = new Date(lastInstanceDate);
    nextInstanceDate.setDate(nextInstanceDate.getDate() + cycle.duration_days);
    return nextInstanceDate.toString();
  };

  // Calculate instance date
  const today = new Date();
  const overdueCycles = cycles.filter(cycle => calculateNextInstanceDate(cycle) < today);
  const upcomingCycles = cycles.filter(cycle => calculateNextInstanceDate(cycle) >= today);
  const noInstanceCycles = cycles.filter(cycle => cycle.instances.length === 0);

  return (
    <Box>
      {/* Button to add a new cycle */}
      <Fab
        color="secondary"
        aria-label="add"
        onClick={handleNewCycle}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>

      {/* Tabs to navigate between Overdue, Not Due Yet, and No Instances */}
      <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="cycle tabs">
        <Tab label="Overdue" />
        <Tab label="Upcoming" />
        <Tab label="No Instances" />
      </Tabs>

      {/* List based on the selected tab */}
      <Box mt={2}>
        {/* Overdue Cycles */}
        {tabIndex === 0 && (
          <List >
            {overdueCycles.map(cycle => (
              <Card key={cycle.id} sx={{ mb: 2 }}>
                <ListItem  onClick={() => handleToggle(cycle.id)}>
                  <ListItemText
                    primary={
                      <Box>
   <Typography variant="h6">
                        {cycle.name}
                      
                      </Typography>
                        <Typography variant="body2" color="error">
                        (Next: {calculateNextInstanceDateString(cycle)})
                      </Typography>
                      </Box>
                   
                    }
                  />
                  <CardActions>
                    <IconButton onClick={() => openModal(cycle)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton  color='error'  onClick={() => handleDeleteCycle(cycle.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {open === cycle.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardActions>
                </ListItem>

                {/* Collapsible section for cycle details */}
                <Collapse in={open === cycle.id} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      Duration: {cycle.duration_days} days
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created: {new Date(cycle.createdAt.toDate()).toLocaleDateString()}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    {cycle.instances.map((instance, index) => (
                      <Typography key={index} variant="body2">
                        {instance.instanceDate
                          ? `Date: ${instance.instanceDate.toDate().toLocaleDateString()}`
                          : ''}
                        {instance.note ? `Note: ${instance.note}` : ''}
                      </Typography>
                    ))}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </List>
        )}

        {/* Upcoming Cycles */}
        {tabIndex === 1 && (
          <List>
            {upcomingCycles.map(cycle => (
              <Card key={cycle.id} sx={{ mb: 2 }}>
                <ListItem  onClick={() => handleToggle(cycle.id)}>
                  <ListItemText
                    primary={
                      <Box>
    <Typography variant="h6">
                        {cycle.name}
                      </Typography>
                        <Typography variant="body2" color="success.main">
                        (Next: {calculateNextInstanceDateString(cycle)})
                      </Typography>
                      </Box>
                  
                    }
                  />
                  <CardActions>
                    <IconButton onClick={() => openModal(cycle)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color='error' onClick={() => handleDeleteCycle(cycle.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {open === cycle.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardActions>
                </ListItem>

                {/* Collapsible section for cycle details */}
                <Collapse in={open === cycle.id} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body2" color="textSecondary">
                      Duration: {cycle.duration_days} days
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Created: {new Date(cycle.createdAt.toDate()).toLocaleDateString()}
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    {cycle.instances.map((instance, index) => (
                      <Typography key={index} variant="body2">
                        {instance.instanceDate
                          ? `Date: ${instance.instanceDate.toDate().toLocaleDateString()}`
                          : ''}
                        {instance.note ? `Note: ${instance.note}` : ''}
                      </Typography>
                    ))}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </List>
        )}

        {/* No Instances Cycles */}
        {tabIndex === 2 && (
          <List >
            {noInstanceCycles.map(cycle => (
              <Card key={cycle.id} sx={{ mb: 2 }}>
                <ListItem  onClick={() => handleToggle(cycle.id)}>
                  <ListItemText
                    primary={
                      <Box>
   <Typography variant="h6">
                        {cycle.name}
                        
                      </Typography>
                      <Typography variant="body2" color="warning.main">
                      (No instances)
                    </Typography>
                      </Box>
                   
                    }
                  />
                  <CardActions>
                    <IconButton onClick={() => openModal(cycle)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton  color='error'  onClick={() => handleDeleteCycle(cycle.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {open === cycle.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CardActions>
                </ListItem>
              </Card>
            ))}
          </List>
        )}
      </Box>

      {/* Modal for Adding or Editing a Cycle */}
      <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="md">
        <DialogTitle>{selectedCycle ? 'Edit Cycle' : 'New Cycle'}</DialogTitle>
        <DialogContent>
          <CycleForm cycle={selectedCycle} onSave={handleSaveCycle} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};



export { CycleForm, CycleList };



const calculateNextInstanceDate = (cycle: Cycle): Date => {
  if (cycle.instances.length === 0) {
    return  new Date(8640000000000000); // No instances to calculate from
  }
  const lastInstanceDate = cycle.instances[cycle.instances.length - 1].instanceDate.toDate(); // Get the last instance date
  const nextInstanceDate = new Date(lastInstanceDate); // Create a new Date object to manipulate
  console.log(nextInstanceDate)
  nextInstanceDate.setDate(nextInstanceDate.getDate() + cycle.duration_days as number); // Add the duration in days
  console.log(nextInstanceDate)
  return nextInstanceDate; // Return the new instance date
};
