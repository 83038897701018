import React, { useState } from 'react';
import {
  List, ListItem, Card, CardHeader, CardContent, Checkbox,
  IconButton, Menu, MenuItem, Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Badge
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReadOnlyMDXEditor } from '../utils/mardkown_render';
import { Timestamp } from '@firebase/firestore';
import { Task } from '../../types/planner';
import TaskFocus from './play-task';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


interface TaskListProps {
  tasks: Task[];
  anchorEl: (HTMLElement | null)[];
  onToggleCompletion: (taskId: string, currentStatus: boolean) => void;
  onEditTask: (task: Task, index: number) => void;
  onDeleteTask: (taskId: string, index: number) => void;
  onMenuClick: (event: React.MouseEvent<HTMLElement>, index: number) => void;
  onMenuClose: (index: number) => void;
  getDueDateColor: (dueDate: Timestamp | undefined) => string;
  calculateDaysSinceCreated: (createdAt: Date) => number;
}

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  anchorEl,
  onToggleCompletion,
  onEditTask,
  onDeleteTask,
  onMenuClick,
  onMenuClose,
  getDueDateColor,
  calculateDaysSinceCreated
}) =>{
  const [searchQuery, setSearchQuery] = useState('');
  const [focusedTask, setFocusedTask] = useState<Task | null>(null); // Task to focus on
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const filteredTasks = tasks.filter(
    (task) =>
      task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.notes?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOpenTaskFocus = (task: Task) => {
    setFocusedTask(task); // Set the task to focus on
    setDialogOpen(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
    setFocusedTask(null); // Reset focused task
  };

  
  return (
    <>
    {/* Filter Bar */}
    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
      <TextField
        label="Search tasks"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </Box>
    
  <List sx={{ width: "100%" }}>
    {filteredTasks.map((task, ind) => (
       <ListItem
       key={task.id}
       sx={{
         width: '100%',
         justifyContent: 'center',
         textDecoration: task.completed ? 'line-through' : 'none',
         padding: { xs: '8px', sm: '12px', md: '16px' }, // Adjust padding for mobile and larger screens
       }}
     >
       <Card
         variant="outlined"
         sx={{
           width: '100%',
           marginBottom: 2,
           padding: { xs: '8px', sm: '12px', md: '16px' }, // Responsive padding
         }}
       >
         <CardHeader
           sx={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}
           avatar={
            <>
             <Checkbox
               checked={task.completed}
               onChange={() => onToggleCompletion(task.id || '', !task.completed)}
               sx={{ color: getDueDateColor(task.dueDate), marginRight: 1 }}
             />
             <Badge
             badgeContent={task.subTasks ? task.subTasks.filter((subTask) => !subTask.completed).length : 0}
             color="primary"
             overlap="circular"
             sx={{
               marginRight: { xs: 1, sm: 2 },
               marginLeft: { xs: 1, sm: 2 },
             }}
           >
           </Badge>
           </>
           }
           title={
             <Box
               sx={{
                 display: 'flex',
                 alignItems: 'center',
                 flexGrow: 1,
                 flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile, horizontally on larger screens
               }}
             >
               <Typography
                 variant="h6"
                 sx={{
                   flexGrow: 1,
                   fontSize: { xs: '1rem', sm: '1.25rem' }, // Responsive font size
                   marginBottom: { xs: '8px', sm: '0px' }, // Add margin on mobile
                 }}
               >
                 {task.title}
               </Typography>
              
            
             </Box>
           }
           
       action={
         <>
           <IconButton
             aria-label="more"
             aria-controls={`menu-${ind}`}
             aria-haspopup="true"
             onClick={(e) => onMenuClick(e, ind)}
           >
             <MoreVertIcon />
           </IconButton>
           <Menu
             id={`menu-${ind}`}
             anchorEl={anchorEl[ind]}
             open={Boolean(anchorEl[ind])}
             onClose={() => onMenuClose(ind)}
           >
                <MenuItem onClick={() => handleOpenTaskFocus(task)}>
                        <PlayCircleOutlineIcon color='secondary' fontSize="small" /> Focus 
              </MenuItem>
             <MenuItem onClick={() => onEditTask(task, ind)}>
               <EditIcon fontSize="small" /> Edit
             </MenuItem>
             <MenuItem onClick={() => onDeleteTask(task.id || '', ind)}>
               <DeleteIcon color="error" fontSize="small" /> Delete
             </MenuItem>
           </Menu>
         </>
       }
     />
     <CardContent sx={{ alignItems: 'right' }}>
     {task.notes && (
         <ReadOnlyMDXEditor markdown={task.notes || ''} textLabel="see notes..." />
       )}
     </CardContent>

     <Box
  component="footer"
  sx={{
    display: 'flex',
    justifyContent: 'space-between',  // Align dates on opposite sides
    padding: 2,
    borderTop: '1px solid #e0e0e0',
  }}
>
  {/* Created Date on the Left */}
  <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
    {`Created: ${calculateDaysSinceCreated(new Date(task.createdAt.toDate()))} days ago`}
  </Typography>

  {/* Due Date on the Right */}
  <Typography
    color={getDueDateColor(task.dueDate)}
    variant="body2"
    sx={{
      padding: 0.5,
      borderRadius: 1,
      fontSize: { xs: '0.75rem', sm: '0.875rem' },  // Smaller font on mobile
    }}
  >
    {task.dueDate && `Due: ${new Date(task.dueDate.toDate()).toLocaleDateString()}`}
  </Typography>
</Box>
   </Card>
 </ListItem>
 
    ))}
  </List>
   {/* Task Focus Dialog */}
   <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullScreen
      >
        <DialogTitle>Focus on Task</DialogTitle>
        <DialogContent>
          {focusedTask && (
            <TaskFocus
              task={focusedTask}
              
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  </>
)};

export default TaskList;
