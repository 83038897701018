import { Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { storage } from '../../firebase';
import { createTimelineEvent, deleteTimelineEvent, updateTimelineEvent } from '../../functions/timeline';
import { ContentItem, TimelineEvent } from '../../types/timeline';
import ContentDisplay from './content';
// styling
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Alert, Box, Card, CardActions, CardHeader, Chip, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LinearProgress from '@mui/material/LinearProgress';
import { LinkMetadata } from '../../types/utils';
import { EditableMDXEditor, ReadOnlyMDXEditor } from '../utils/mardkown_render';
import { v4 as uuidv4 } from 'uuid';



interface TimelineEventProps {
  entry: TimelineEvent;
  onEntryUpdated: (entry: TimelineEvent) => void;
  onEntryDeleted: (entryId: string) => void;
}

interface TimelineEventFormProps {
  userId: string;
  entry?: TimelineEvent;
  onSave: (entry: TimelineEvent) => void;
  onClose?: () => void;
}
const TimelineEventComponent: React.FC<TimelineEventProps> = ({ entry, onEntryDeleted }) => {
  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [thisEntry, setEntry] = useState<TimelineEvent>(entry)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    handleMenuClose();
  }
  const handleCloseModal = () => setModalOpen(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (thisEntry.id) {
      await deleteTimelineEvent(thisEntry.id, thisEntry.content[0]?.url);
      onEntryDeleted(thisEntry.id);
    }
    setConfirmationDialogOpen(false);
    handleMenuClose(); // Close menu after action
  };

  const openDeleteConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
    handleMenuClose();
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);

  };

  const handleSave= (tl : TimelineEvent)  =>{
    setEntry(tl)
    handleCloseModal()
  }

  return (
    <Card variant="outlined">
    <CardHeader
      action={
        <>
          <IconButton
            aria-label="settings"
            aria-controls="crud-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="crud-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
            <MenuItem color="error" onClick={openDeleteConfirmationDialog}>
              Delete
            </MenuItem>
          </Menu>
        </>
      }
      title={thisEntry.title.length > 0 && thisEntry.title}
    />
  
    {/* Media content display */}
    {thisEntry.content.length > 0 ? (
      <>
        <ContentDisplay items={thisEntry?.content} />
  
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {thisEntry.caption && <ReadOnlyMDXEditor markdown={thisEntry.caption} />}
            
            <Typography variant="subtitle2" color="textSecondary">
              {`Posted on: ${thisEntry.createdAt.toDate().toLocaleDateString()} at ${thisEntry.createdAt.toDate().toLocaleTimeString()}`}
            </Typography>
          </CardContent>
        </Collapse>
      </>
    ) : (
      <CardContent>
        {thisEntry.caption && <ReadOnlyMDXEditor markdown={thisEntry.caption} />}
        
        <Typography variant="subtitle2" color="textSecondary">
          {`Posted on: ${thisEntry.createdAt.toDate().toLocaleDateString()} at ${thisEntry.createdAt.toDate().toLocaleTimeString()}`}
        </Typography>
      </CardContent>
    )}
  
    <CardActions disableSpacing>
      {thisEntry.content.length > 0 ? (
        <IconButton
          aria-label="show more"
          onClick={handleExpandClick}
          sx={{
            transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
            marginLeft: 'auto',
            transition: 'transform 0.3s',
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      ) : null}
      
      {thisEntry.tags.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {thisEntry.tags.map((tag, index) => (
            <Chip key={index} label={tag} />
          ))}
        </Box>
      )}
    </CardActions>
  
    {/* Modal for Editing */}
    <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
      <TimelineEventForm userId={thisEntry.userId} entry={thisEntry} onSave={handleSave} onClose={handleCloseModal} />
      <DialogActions>
        <Button color="error" onClick={handleCloseModal}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  
    {/* Confirmation Dialog for Deletion */}
    <Dialog
      open={confirmationDialogOpen}
      onClose={handleCloseConfirmationDialog}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <DialogTitle id="confirm-delete-title">Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-description">
          Are you sure you want to delete this event? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmationDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  </Card>
  
  
  );
};

const TimelineEventForm: React.FC<TimelineEventFormProps> = ({ userId, entry, onSave, onClose }) => {
  const [title, setTitle] = useState<string>(entry?.title || '');
  const [caption, setCaption] = useState<string | undefined>(entry?.caption );
  const [tags, setTags] = useState<string[]>(entry?.tags || []);
  const [tagInput, setTagInput] = useState<string>('');
  const [contentItems, setContentItems] = useState<ContentItem[]>(entry?.content || []);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    if (entry) {
      setTitle(entry.title);
      setCaption(entry.caption);
      setTags(entry.tags);
      setContentItems(entry.content);
    }
  }, [entry]);

  const handleDeleteTag = (tagToDelete: string) => {
    setTags((prevTags) => prevTags.filter(tag => tag !== tagToDelete));
  };

  const handleTagInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTagInput(event.target.value);
  };

  const handleAddTag = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && tagInput && !tags.includes(tagInput.trim())) {
      event.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput(''); // Clear the input after adding a tag
    }
  };

  const uploadImageAndGetURL = async (file: File, type: string): Promise<string> => {
    const uniqueFileName = `${uuidv4()}${file.name.substring(file.name.lastIndexOf('.'))}`;
  
    const storageRef = ref(storage, `timeline/${userId}/${type}/${uniqueFileName}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];
      const type = file.type.startsWith('image') ? 'image' : 'video';

      setUploading(true);

      try {
        const imageURL = await uploadImageAndGetURL(file, type);
        setContentItems([{ type: type, url: imageURL } as ContentItem]);
        console.log(imageURL)
      } catch (error) {
        console.error('Error uploading file:', error);
        setSnackbarMessage('Error uploading file');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setUploading(false);
      }
    }
  };



  const fetchMetadata = async (url: string): Promise<LinkMetadata | undefined> => {
    try {

      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'X-Linkpreview-Api-Key': process.env.REACT_APP_LINKPREV_API_KEY
        },
      } as RequestInit
      const req_url = `https://api.linkpreview.net/?q=${encodeURIComponent(url)}`
      const response = await fetch(req_url, requestOptions);
      const data = await response.json();
      return data as LinkMetadata
    } catch (error) {
      console.error('Failed to fetch metadata', error);
    }
  };


  const determineContentType = async (url: string) => {
    if (url.match(/\.(jpeg|jpg|gif|png|svg)$/i)) {
      return { type: 'image', url };
    } else if (url.match(/\.(mp4|webm|ogg)$/i)) {
      return { type: 'video', url };
    } else if (url.match(/(?:youtube\.com\/.*list=)([\w-]+)/i)) {
      const playlistId = url.match(/(?:youtube\.com\/.*list=)([\w-]+)/i)?.[1];
      return { type: 'yt-playable', url: `https://www.youtube.com/embed/videoseries?list=${playlistId}` };
    } else if (url.match(/(?:youtu\.be\/|(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^"&?\/\s]{11}))/i)) {
      const videoId = url.match(/(?:youtu\.be\/|(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=))([^"&?\/\s]{11}))/i)?.[1];
      if (videoId) {
        return { type: 'youtube', url: `https://www.youtube.com/embed/${videoId}` };
      }
    }
    const metadata = await fetchMetadata(url);
    if (metadata) {
      return { type: 'link', url: url, previewImage: metadata.image, title: metadata.title, description: metadata.description };
    }
  };
  const handleContentDropPaste = (url: string) => {

    const content = determineContentType(url).then(content => {
      if (content) {
        setContentItems([content as ContentItem]);

      }
    }
    )

  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // Necessary to allow the drop event
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const url = e.dataTransfer.getData('URL') || e.dataTransfer.getData('text');

    if (url) {
      handleContentDropPaste(url);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const text = e.clipboardData.getData('text/plain');
    handleContentDropPaste(text);
  };

  const handleFocus = async (event: React.FocusEvent<HTMLDivElement>) => {
    setUploading(true);
    try {
      const items = await navigator.clipboard.read();
      if (await items[0].getType('image/png') || await items[0].getType('image/jpeg') || await items[0].getType('image/gif')) {
        const blobType = items[0].types.find(type => type.startsWith('image')); // Get the image type
        if (blobType) {
          const blob = await items[0].getType(blobType);
          const file = new File([blob], `pastedImage.${blobType.split('/')[1]}`, { type: blob.type, lastModified: new Date().getTime() });
          const imageUrl = await uploadImageAndGetURL(file, "image");
          setContentItems([{ type: "image", url: imageUrl } as ContentItem])
        }
      }

    } catch {
      const clipboardText = await navigator.clipboard.readText();
      handleContentDropPaste(clipboardText);
    }

    setUploading(false);
  };



  const handleSubmit = async () => {
    setUploading(true);

    try {
      const updatedEntry: TimelineEvent = {
        title,
        userId,
        content: contentItems,
        caption: caption || "",
        likes: entry?.likes || [],
        comments: entry?.comments || [],
        createdAt: entry?.createdAt || Timestamp.now(),
        updatedAt: Timestamp.now(),
        tags,
        visibility: 'public',
      };

      if (entry && entry.id) {
        await updateTimelineEvent(entry.id, updatedEntry);
        setSnackbarMessage('Entry updated successfully');
      } else {
        await createTimelineEvent(updatedEntry);
        setSnackbarMessage('Entry created successfully');
      }

      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onSave(updatedEntry);
    } catch (error) {
      console.error('Error saving document:', error);
      setSnackbarMessage('Failed to save entry');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteContent = () => {
    setContentItems([]);
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h6">{entry ? 'Edit' : 'Create'} Entry</Typography>
      <TextField
        fullWidth
        placeholder="Title"
        value={title}
        onChange={e => setTitle(e.target.value)}
        margin="normal"
      />


      <Card>
        <CardContent sx={{
          border: '1px dashed gray',
          padding: 5,
          marginBottom: 2
        }}>
          <Box
            tabIndex={0}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onPaste={handlePaste}
            onFocus={handleFocus}

            sx={{
              border: '1px dashed gray',
              padding: 5,
              marginBottom: 2
            }}
          >
            <Typography variant="body2">Drag and drop a URL here, or paste it with Ctrl+V</Typography>

          </Box>
          <Button onClick={handleDeleteContent} color="error" size="small">
            <TextField
              type="file"
              onChange={handleImageChange}
              fullWidth
              margin="normal"
              sx={{ mt: 2 }} // Add top margin
            />
          </Button>

          <Button onClick={handleDeleteContent} color="error" size="small">
            Remove Content
          </Button>
        </CardContent>
      </Card>

 <EditableMDXEditor onChange={setCaption} markdown={entry?.caption || ""} />
      <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '95%' }}>
        <Box sx={{ width: '50%', m: 2 }}>
          <Typography variant="subtitle1">Current Image</Typography>
          <ContentDisplay items={entry?.content || []} />
        </Box>
        <Box sx={{ width: '50%', m: 2 }}>
          <Typography variant="subtitle1">Staged Image</Typography>
          <ContentDisplay items={contentItems} />
        </Box>
      </Box>

      {uploading && <Box><LinearProgress color="secondary" /></Box>}

      <Box sx={{ marginTop: 2 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteTag(tag)}
            color="primary"
            variant="outlined"
            sx={{ m: 1 }}
          />
        ))}
        <TextField
          fullWidth
          label="Add a tag"
          value={tagInput}
          onChange={handleTagInputChange}
          onKeyPress={handleAddTag}
          margin="normal"
          placeholder="Press enter to add tags"
        />
      </Box>
      <Button onClick={handleSubmit}  color='warning' size="small">
        {entry ? 'Update' : 'Submit'}
      </Button>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};


export { TimelineEventComponent, TimelineEventForm };


