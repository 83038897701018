import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    text : string,
}
const ExpandableTypography:  React.FC<Props>= ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  return (
    <Box>
      <Typography>
        {isExpanded ? text : `${text.substring(0, 50)}...`}
      </Typography>
      <IconButton onClick={toggleExpansion} aria-label="...">
        {isExpanded ? <ExpandLessIcon/> : "..."}
      </IconButton>
    </Box>
  );
};

export default ExpandableTypography;
