import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { addTask, deleteTask, getTasks, toggleTaskCompletion, updateTask } from '../../functions/tasks';
import { SubTask, Task } from '../../types/planner';
import { CycleList } from '../cycles/cycles';
import DeleteIcon from '@mui/icons-material/Delete';
import '@mdxeditor/editor/style.css';
import { EditableMDXEditor } from '../utils/mardkown_render';
import TaskList from './task_list';




interface TaskFormProps {
  onSave: (task: Task) => void;
  initialTask?: Task;
}

const TaskForm: React.FC<TaskFormProps> = ({ onSave, initialTask }) => {
  const [taskTitle, setTaskTitle] = useState(initialTask?.title || '');
  const [subTasks, setSubTasks] = useState(initialTask?.subTasks || [])
  const [dueDate, setDueDate] = useState<Timestamp | null>(initialTask?.dueDate || null);
  const [notes, setNotes] = useState(initialTask?.notes || ''); // Added notes state
  const { currentUser } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSave = async () => {
    const task: Task = {
      ...initialTask,
      userId: currentUser.uid,
      title: taskTitle,
      notes: notes,
      subTasks: subTasks,
      completed: initialTask?.completed || false,
      createdAt: initialTask?.createdAt || Timestamp.now(),
      dueDate: dueDate || undefined,
    };
    try {
      if (task.id) {
        await updateTask(task, currentUser.uid);
        setDialogOpen(true); // Show success dialog
      } else {
        const taskId = await addTask(task, currentUser.uid);
        task.id = taskId;
        setDialogOpen(true); // Show success dialog
      }
      onSave(task);
      resetState()
      
    } catch (error) {
      setSnackbarMessage('Error saving task');
      setSnackbarOpen(true); // Show error snackbar
    }


  };

  const resetState = async() => {
    setTaskTitle('')
    setDueDate(null)
    setNotes('')
  }
  const handleDialogClose = () => {
    setDialogOpen(false);
    

  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: '90vw',
          maxWidth: '100%',
        }}
      >
        <TextField
          label="Task Title"
          multiline
          rows={1}
          value={taskTitle}
          onChange={(e) => setTaskTitle(e.target.value)}
          fullWidth
        />

          {/* SubTaskForm */}
          <SubTaskForm
          subTasks={subTasks}
          onUpdateSubTasks={setSubTasks} // Pass state update function to SubTaskForm
        />
        <Box
        >
          <EditableMDXEditor
          markdown= {initialTask?.notes || ''}
            onChange={setNotes}

          />


        </Box>

        <TextField
          label="Due Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dueDate ? dueDate.toDate().toISOString().split('T')[0] : ''}
          onChange={(e) => setDueDate(Timestamp.fromDate(new Date(e.target.value)))}
          fullWidth
        />
        <Button onClick={handleSave} color="warning">
          {initialTask ? 'Update Task' : 'Add Task'}
        </Button>
      </Stack>

      {/* Success Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{initialTask ? 'Task Updated' : 'Task Created'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {initialTask
              ? 'Your task has been successfully updated.'
              : 'Your new task has been successfully created.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Error Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};



interface SubTaskFormProps {
  subTasks: SubTask[];
  onUpdateSubTasks: (updatedSubTasks: SubTask[]) => void;
}

const SubTaskForm: React.FC<SubTaskFormProps> = ({ subTasks, onUpdateSubTasks }) => {
  const [newSubTask, setNewSubTask] = useState('');

  const handleAddSubTask = () => {
    if (newSubTask.trim() === '') return;
    const updatedSubTasks = [
      ...subTasks,
      { description: newSubTask, completed: false },
    ];
    onUpdateSubTasks(updatedSubTasks);
    setNewSubTask('');
  };

  const handleToggleSubTask = (index: number) => {
    const updatedSubTasks = subTasks.map((subTask, i) =>
      i === index ? { ...subTask, completed: !subTask.completed } : subTask
    );
    onUpdateSubTasks(updatedSubTasks);
  };

  const handleRemoveSubTask = (index: number) => {
    const updatedSubTasks = subTasks.filter((_, i) => i !== index);
    onUpdateSubTasks(updatedSubTasks);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Subtasks</Typography>
      <List>
        {subTasks.map((subTask, index) => (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={subTask.completed}
              onChange={() => handleToggleSubTask(index)}
            />
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              {subTask.description}
            </Typography>
            <IconButton onClick={() => handleRemoveSubTask(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextField
          label="New Subtask"
          value={newSubTask}
          onChange={(e) => setNewSubTask(e.target.value)}
          fullWidth
        />
        <Button
          sx={{ ml: 2 }}
          onClick={handleAddSubTask}
        >
          Add
        </Button>
      </Box>
    </Box>
  );
};




const TodoApp: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [editingTask, setEditingTask] = useState<Task | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
  const [showTodayTasks, setShowTodayTasks] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const { currentUser } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<(HTMLElement | null)[]>([]);


  useEffect(() => {
    fetchTasks();
  }, [currentUser]);

  const fetchTasks = async () => {
    const fetchedTasks = await getTasks(currentUser.uid);
    setTasks(fetchedTasks);
  };

  const handleSaveTask = async (task: Task) => {
    setIsModalOpen(false);
    setSnackbarMessage(task.id ? 'Task updated successfully!' : 'Task added successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    await upsertTask(task)
    
  };


  const upsertTask = async(newTask: Task) => {
    setTasks(prevTasks => {
      // Check if the task already exists in the list
      const taskIndex = prevTasks.findIndex(task => task.id === newTask.id);
  
      if (taskIndex > -1) {
        // Task exists, update it
        const updatedTasks = [...prevTasks];
        updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], ...newTask };
        return updatedTasks;
      } else {
        // Task does not exist, add it
        return [newTask, ...prevTasks];
      }
    });
  };

  
  const handleDeleteTask = (taskId: string, index: number) => {
    setTaskToDelete(taskId);
    setConfirmationDialogOpen(true);
    handleMenuClose(index)
  };

  const confirmDeleteTask = async () => {
    if (taskToDelete) {
      try {
        await deleteTask(taskToDelete);
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskToDelete));
        setSnackbarMessage('Task deleted successfully!');
        setSnackbarSeverity('success');
      } catch (error) {
        setSnackbarMessage('Error deleting task');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setTaskToDelete(null);
        setConfirmationDialogOpen(false);
      }
    }
  };

  const handleToggleTaskCompletion = async (taskId: string, currentStatus: boolean) => {
    try {
      await toggleTaskCompletion(taskId, currentStatus);
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, completed: !task.completed } : task
        )
      );
    } catch (error) {
      setSnackbarMessage('Error toggling task completion');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditTask = (task: Task, index: number) => {
    setEditingTask(task);
    setIsModalOpen(true);
    handleMenuClose(index)

  };

  const calculateDaysSinceCreated = (createdAt: Date) => {
    const now = new Date();
    const diff = now.getTime() - createdAt.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  const isToday = (date: Timestamp | undefined) => {
    if (!date) return false;
    const today = new Date();
    const taskDate = date.toDate();
    return (
      taskDate.getDate() === today.getDate() &&
      taskDate.getMonth() === today.getMonth() &&
      taskDate.getFullYear() === today.getFullYear()
    );
  };

  const filteredTasks = showTodayTasks
    ? tasks.filter((task) => isToday(task.createdAt) || isToday(task.dueDate))
    : tasks;

  const completedTasks = filteredTasks.filter((task) => task.completed);
  const uncompletedTasks = filteredTasks.filter((task) => !task.completed);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const toggleTodayTasks = () => {
    setShowTodayTasks(!showTodayTasks);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingTask(undefined);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getDueDateColor = (dueDate: Timestamp | undefined) => {
    if (!dueDate) return 'inherit';

    const dueDateObj = dueDate.toDate();
    const today = new Date();

    today.setHours(0, 0, 0, 0); // Clear hours, minutes, seconds, and milliseconds
    dueDateObj.setHours(0, 0, 0, 0);

    if (dueDateObj > today) {
      return "#00FFB2" // Mint green for future dates
    } else if (dueDateObj.getTime() === today.getTime()) {
      return '#FFFF00'; // Yellow for today
    } else {
      return '#FF1744'; // Red for past dates
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEl(newAnchorEls);
  };

  const handleMenuClose = (index: number) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = null;
    setAnchorEl(newAnchorEls);
  };

  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="text.secondary" variant="h5" gutterBottom>
              Add Task
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%' }}>
              <TaskForm onSave={handleSaveTask} />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ marginY: 4 }} />
      </Box>


      <Button
        onClick={toggleTodayTasks}
        color={showTodayTasks ? 'error' : 'primary'}
        sx={{ marginBottom: 2 }}
      >
        {showTodayTasks ? "Show All Tasks" : "Show Today's Tasks"}
      </Button>
      
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="task tabs">
        <Tab label="Uncompleted Tasks" />
        <Tab label="Completed Tasks" />
        <Tab label="Cycles" disabled />
      </Tabs>

      {tabIndex === 0 && (
        <Box sx={{ pb: 7, width: "100%" }}>
          <TaskList
            tasks={uncompletedTasks}
            anchorEl={anchorEl}
            onToggleCompletion={handleToggleTaskCompletion}
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
            onMenuClick={handleMenuClick}
            onMenuClose={handleMenuClose}
            getDueDateColor={getDueDateColor}
            calculateDaysSinceCreated={calculateDaysSinceCreated}
          />
        </Box>
      )}

      {tabIndex === 1 && (
        <Box sx={{ pb: 7, width: "100%" }}>
          <TaskList
            tasks={completedTasks}
            anchorEl={anchorEl}
            onToggleCompletion={handleToggleTaskCompletion}
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
            onMenuClick={handleMenuClick}
            onMenuClose={handleMenuClose}
            getDueDateColor={getDueDateColor}
            calculateDaysSinceCreated={calculateDaysSinceCreated}
          />
        </Box>
      )}

      {tabIndex === 2 && <CycleList />}

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="edit-task-modal-title"
        aria-describedby="edit-task-modal-description"
        fullWidth maxWidth="md" fullScreen>

        <DialogContent>
          <TaskForm onSave={handleSaveTask} initialTask={editingTask} />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
        <DialogTitle>Confirm Task Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this task?</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteTask} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Box>
  );
};




export { TaskForm, TodoApp, SubTaskForm };

