import { Box, Button, Dialog, DialogActions } from "@mui/material";
import  React, { useState } from "react";
import Hangman from "./hangman/hangman";
import MadLibs from "./madlibs/madlibs";
import { PageAppBar } from "../pages/navbar";
import GroundingExerciseStepper from "./mindfulness/grounding_exercise";

const Games = ({ }) => {
    const [isHMOpen, setIsHMOpen] = useState<boolean>(false);
    const [isMLOpen, setIsMLOpen] = useState<boolean>(false);
    const [isGroundingOpen, setIsGroundingOpen] = useState<boolean>(false);
    const handleOpenHM = () => setIsHMOpen(true);
    const handleOpenML = () => setIsMLOpen(true);
    const handleOpenGrounding = () => setIsGroundingOpen(true);

 
    const handleCloseHM = () => setIsHMOpen(false);
    const handleCloseML = () => setIsMLOpen(false);
    const handleCloseGrounding  = () => setIsGroundingOpen(false);


    
    return (
      <>
        <Box  sx={{   mt: 7 ,  bp: 7, mb: 10 }}>
        <PageAppBar pageTitle="Games"/>
        <Button size="small" onClick={handleOpenHM}>
          Hangman
        </Button>
        <Button size="small" onClick={handleOpenML}>
          Madlibs
        </Button>
        <Button size="small" onClick={handleOpenGrounding}>
          Grounding
        </Button>
        <Dialog open={isMLOpen} onClose={handleCloseML} fullWidth maxWidth="md"  fullScreen>
        <MadLibs />
        <DialogActions>
          <Button  color="error" onClick={handleCloseML}>Cancel</Button>
        </DialogActions>
      </Dialog>

                <Dialog open={isHMOpen} onClose={handleCloseHM} fullWidth maxWidth="md"  fullScreen>
        <Hangman />
        <DialogActions>
          <Button  color="error" onClick={handleCloseHM}>Cancel</Button>
        </DialogActions>
      </Dialog>

                <Dialog open={isGroundingOpen} onClose={handleCloseGrounding} fullWidth maxWidth="md"  fullScreen>
        <GroundingExerciseStepper />
        <DialogActions>
          <Button  color="error" onClick={handleCloseGrounding}>Cancel</Button>
        </DialogActions>
      </Dialog>
        </Box>
      </>
    );
  };

  export default Games