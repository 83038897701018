import Masonry from '@mui/lab/Masonry';
import { Alert, Box, Button, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Snackbar, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createTimelineEvent } from '../../functions/timeline';
import { ImageContent, TimelineEvent } from '../../types/timeline';

interface Article {
    title: string;
    description: string;
    url: string;
    published_at: string;
    source: string;
    image: string;
}

const MSNewsWidget: React.FC = () => {
    const [articles, setArticles] = useState<Article[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const baseUrl = 'https://api.mediastack.com/v1/news';
        const sources = 'nytimes,washington-post,reuters,the-guardian-uk,nbc-news,abc-news,ars-technica,techcrunch,hacker-news,wired,the-verge,cnet,engadget,kotaku,polygon';
        const params = `?access_key=${process.env.REACT_APP_MEDIASTACK_API_KEY}&sources=${sources}&languages=en&categories=-sports&limit=100`;
        fetch(baseUrl + params)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setArticles(data.data);
                setIsLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setIsLoading(false);
            });
    }, []);

    const formatDate = (dateString: string) => {
        return moment(dateString).format('MMMM DD, YYYY');
    };

    function convertArticleToTimelineEvent(article: Article) {
        const timelineEvent: TimelineEvent = {
            userId: currentUser.uid,
            title: article.title,
            content: [],
          caption: undefined,
            likes: [],
            comments: [],
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            tags: [article.source],
            visibility: 'public',
        };

        if (article.image) {
            const imageContent: ImageContent = {
                type: 'image',
                url: article.image,
                description: article.description,
                altText: 'Image for ' + article.title
            }
            timelineEvent.content.push(imageContent);
        }else{
            timelineEvent.caption = `${article.description}\n${article.url}\n${article.published_at}\t${article.source}`
        }
      
        createTimelineEvent(timelineEvent);
        setSnackbarMessage("Article saved to Timeline");
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (isLoading) return <CircularProgress />;
    if (error) return <Typography variant="body1" color="error">Error: {error}</Typography>;

    return (
        <Box>
            <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
                {articles.map((article, index) => (
                    <Card key={index} variant="outlined">
                        <CardActionArea onClick={() => window.open(article.url, '_blank')}>
                            {article.image && (
                                <CardMedia
                                    component="img"
                                    image={article.image}
                                    alt={`Cover Image for ${article.title}`}
                                />
                            )}
                            <CardContent>
                                <Typography gutterBottom variant="h5" >
                                    {article.title}
                                </Typography>
                                <Typography variant="body2">
                                    {article.description}
                                </Typography>
                                <Typography variant="overline" color="text.secondary" display="block" gutterBottom>
                                    Published: {formatDate(article.published_at)}
                                </Typography>
                                <Typography variant="overline" color="text.secondary" display="block">
                                    Source: {article.source}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <Button color="warning" size="small" onClick={() => convertArticleToTimelineEvent(article)}>
                            Convert to Event
                        </Button>
                    </Card>
                ))}
            </Masonry>

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MSNewsWidget;
