import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  Card,
  CardContent,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { SubTask, Task } from '../../types/planner';
import { updateTask } from '../../functions/tasks';
import { SubTaskForm } from './tasks';
import { ReadOnlyMDXEditor } from '../utils/mardkown_render';

interface TaskFocusProps {
  task: Task;
}

const TaskFocus: React.FC<TaskFocusProps> = ({ task }) => {
  const [timer, setTimer] = useState(1500); // 25 min for deep work session
  const [isRunning, setIsRunning] = useState(false);
  const [logEntry, setLogEntry] = useState('');
  const [notes, setNotes] = useState(task.notes || '');
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [subTasks, setSubTasks] = useState(task.subTasks || []);

  useEffect(() => {
    if (isRunning && timer > 0) {
      timerRef.current = setTimeout(() => setTimer(timer - 1), 1000);
    } else if (timer === 0) {
      setIsRunning(false);
      playBeep();
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isRunning, timer]);

  const playBeep = () => {
    const context = new AudioContext();
    const oscillator = context.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(440, context.currentTime); // Set frequency to 440Hz (A4)
    oscillator.connect(context.destination);
    oscillator.start();
    oscillator.stop(context.currentTime + 0.5); // 0.5 second beep
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleAddLog = () => {
    const timestamp = new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    
    const newNotes = `${notes}\n\n[${timestamp}] ${logEntry}`;
    setNotes(newNotes);
    setLogEntry('');
    const updatedTask = { ...task, notes: newNotes, subTasks: subTasks };
    updateTask(updatedTask, task.userId); // Save the task with the new notes
  };


  const handleSaveSTask = (tasks : SubTask[]) => {
    setSubTasks(tasks)
    const updatedTask = { ...task, subTasks: tasks };
    console.log(updatedTask)
    console.log(tasks)
    updateTask(updatedTask, task.userId);
  };

  const handleStartPause = () => {
    setIsRunning(!isRunning);
  };

  const handleResetTimer = () => {
    setTimer(1500); // Reset to 25 minutes
    setIsRunning(false);
  };

  return (
    <Card >
      <CardContent>
        <Typography variant="h4" color='red' gutterBottom>{task.title}</Typography>
        <Typography variant="body1" gutterBottom>
          Due Date: {task.dueDate ? task.dueDate.toDate().toLocaleDateString() : 'No Due Date'}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography variant="h6">Deep Work Timer</Typography>
          <Typography variant="h2" sx={{ fontWeight: 'bold' }}>{formatTime(timer)}</Typography>
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Button color="primary" onClick={handleStartPause}>
              {isRunning ? 'Pause' : 'Start'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleResetTimer}>
              Reset Timer
            </Button>
          </Stack>
        </Box>
        
        <Box mt={4}>
        <SubTaskForm
        subTasks={subTasks}
        onUpdateSubTasks={handleSaveSTask}  // Manage subTasks within TaskFocus
      />

          <Typography variant="h6">Task Notes / Log</Typography>
          <ReadOnlyMDXEditor markdown={notes} />
        </Box>
        <Box mt={3}>
          <TextField
            label="Add log entry"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            value={logEntry}
            onChange={(e) => setLogEntry(e.target.value)}
          />
          <Button
            color="secondary"
            onClick={handleAddLog}
            fullWidth
            sx={{ mt: 2 }}
          >
            Add Log Entry
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskFocus;
