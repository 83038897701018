import { db, gem_fitness_coach } from "../firebase";
import { Checkin, Plan } from "../types/planner"; // Adjust the import based on your project structure
import { collection, addDoc, Timestamp, updateDoc, deleteDoc, getDoc, doc, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import { fetchAllExistAttributes, fetchAvgExistAttributes } from "./utils";
import { ChecklistState, CoachingSettings, Workout } from "../types/fitness";
import { Meal } from "../types/nutrition";
import { ExistAttribute } from "../types/utils";
import { Settings } from "@mui/icons-material";
import { GenerationConfig } from "firebase/vertexai-preview";

const checkinsCollection = collection(db, "checkins");

export const createCheckin = async (checkin: Checkin): Promise<void> => {
    try {
        const docRef = await addDoc(checkinsCollection, checkin);
        checkin.id = docRef.id;
        updateCheckinById(checkin.id, checkin)
    } catch (error) {
        console.error("Error adding check-in: ", error);
    }
};

// Read a check-in by ID
export const getCheckinById = async (id: string | undefined): Promise<Checkin | null> => {
    try {
        if (!id) return null;
        const docRef = doc(checkinsCollection, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data() as Checkin;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error getting check-in: ", error);
        return null;
    }
};

// Update a check-in by ID
export const updateCheckinById = async (id: string, checkin: Partial<Checkin>): Promise<void> => {
    try {
        const docRef = doc(checkinsCollection, id);
        await updateDoc(docRef, checkin);
    } catch (error) {
        console.error("Error updating check-in: ", error);
    }
};

// Delete a check-in by ID
export const deleteCheckinById = async (id: string): Promise<void> => {
    try {
        const docRef = doc(checkinsCollection, id);
        await deleteDoc(docRef);
    } catch (error) {
        console.error("Error deleting check-in: ", error);
    }
};

// List all check-ins for a specific user
export const listCheckinsByUser = async (userId: string): Promise<Checkin[]> => {
    try {
        const q = query(checkinsCollection, where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => doc.data() as Checkin);
    } catch (error) {
        console.error("Error listing check-ins: ", error);
        return [];
    }
};

// Get the most recent check-in for a specific user
export const getLastCheckinByUser = async (userId: string): Promise<Checkin | null> => {
    try {
        const q = query(
            checkinsCollection,
            where("userId", "==", userId),
            orderBy("date", "desc"),
            limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            return querySnapshot.docs[0].data() as Checkin;
        } else {
            console.error("No check-ins found for this user!");
            return null;
        }
    } catch (error) {
        console.error("Error getting the last check-in: ", error);
        return null;
    }
};





export async function GeminiPlan(context: string): Promise<Plan> {
    let raw: string;
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-indexed
    const year = today.getFullYear();

    raw = JSON.stringify({

        "inputs": [
            {
                "data": {
                    "text": {
                        "raw": `
                         Create a meal plan, tasks and/or a workout for me today.
                       Today is ${month}/${day}/${year}. Play attention to the dates in the context. I am not a professional athlete and only wish to be a 
                    healthier person. Based on my goals, give me insights into what I am doing well, and what I should improve on. Give me a Plan for today that will help me achieve my goals. 
                    The following is the context: ${context}.  Create a meal plan and a workout for me today. Use my history to suggest  workouts and meals for today with enough difference to give variety.
                    return a json representation of a Plan. Return only the Plan, nothing else. Do not nest the data.:

                  ` 
                    }
                }
            }
        ]
    })



    try {
        const result = await gem_fitness_coach.generateContent(raw);
        const response = result.response;
      const outputs = (await response).text();
        const res = JSON.parse(outputs) as Plan;
       
        res.tasks = res.tasks.map(task => ({
            ...task,
            createdAt: Timestamp.now(),
            dueDate:  Timestamp.now(),
          }));
      console.log(outputs)
        return res

    } catch (error) {
        console.error('Fetch error:', error);
        return {} as Plan;
    }

}



export const GeneratePlanConetxt = async (userId: string): Promise<string> => {
    let ctx: string = "";
    const today = new Date();

    // Step 2: Subtract one day
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Step 3: Format the date as needed
    const year = yesterday.getFullYear();
    const month = (yesterday.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = yesterday.getDate().toString().padStart(2, '0');

    const formattedYesterday = `${year}-${month}-${day}`;

    let fetchedMeals: Meal[] = [];
    const settingsRef = doc(db, "coachingSettings", userId);
    const docSnap = await getDoc(settingsRef);
    if (docSnap.exists()) {
        const settings = docSnap.data() as CoachingSettings;
        if (settings.ExistsKey) {
            const attrs = await fetchAllExistAttributes(settings.ExistsKey, formattedYesterday);
            ctx += attrs.map(attr => {
                const valuesText = attr.values.map(val => `Date: ${val.date}, Value: ${val.value}`).join('; ');
                return `Attribute: ${attr.label}, Service: ${attr.service.name}, Group: ${attr.group.name}, Values: [${valuesText}]`;
            }).join('\n');

            const metrics = await fetchAvgExistAttributes(settings.ExistsKey);
            ctx += `AVERAGES FOR THE WEEK Days and Overall\n`
             ctx += metrics.map(metric => {
                const dailyMetrics = `Monday: ${metric.monday}, Tuesday: ${metric.tuesday}, Wednesday: ${metric.wednesday}, Thursday: ${metric.thursday}, Friday: ${metric.friday}, Saturday: ${metric.saturday}, Sunday: ${metric.sunday}`;
                return `Attribute: ${metric.user_attribute}, Date: ${metric.date}, Overall: ${metric.overall}, Daily Averages: { ${dailyMetrics}`;
              }).join('\n');
            }

    ctx += `\ngoals ${settings.AICoachSystem}`
    ctx += `\nAge ${settings.Age}`
    ctx += `\nGender ${settings.Gender}`
    ctx += `\nHeight ${settings.height_cm}`
    ctx += `\nWeight ${settings.weight} kg`
    ctx += `\npreferred workout styles ${settings.preferredWorkouts}`
    ctx += `\nfood restrictions ${settings.foodRestrictions}`

    }
    const q = query(collection(db, "Meals"), where("userId", "==", userId), orderBy("createdAt", "desc"), limit(20));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(doc => {
        const meal = doc.data() as Meal;

        const infoText = meal.info.map(info =>
            `Description: ${info.description}, Calories: ${info.calories}, Protein: ${info.protein}g, Fat: ${info.fat}g, Carbs: ${info.carbs}g, Key Ingredients: ${info.key_ingredients.join(', ')}`
        ).join('; ');
        ctx += `\nMeal Notes: ${meal.notes}, Nutrition Info: [${infoText}]`;
    });

    const wq = query(collection(db, "workout_log"), where("createdBy", "==", userId), orderBy("createdAt", "desc"), limit(5));
    const squerySnapshot = await getDocs(wq);
    squerySnapshot.forEach(doc => {
        let log = doc.data() as Workout
        const exercisesText = log.exercises.map(exercise =>
            `Exercise: ${exercise.exercise}, Reps: ${exercise.reps}, Sets: ${exercise.sets}, Weight: ${exercise.weight}, Duration: ${exercise.duration} seconds, Order: ${exercise.order}`
        ).join('; ');
        ctx += `\nWorkout Title: ${log.title}, Description: ${log.description}, Exercises: [${exercisesText}]`;

    });
    ctx += "USER CHECKIN FOR TODAY\n"
    const checkin = await getLastCheckinByUser(userId)
    if (checkin) {
        const soreText = checkin.sore.join(', ');
        ctx += `Mood: ${checkin.mood}, Sore: [${soreText}], Injuries: ${checkin.injuries}, Energy: ${checkin.energy}, Busy: ${checkin.busy}, Notes: ${checkin.notes}, Date: ${checkin.date.toDate().toLocaleDateString()}`;

    }

    return ctx + fetchedMeals.toString()


}


export const checkCheckinToday = async (userId: string): Promise<boolean> => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

    const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDay);

    const q = query(
        checkinsCollection,
        where("userId", "==", userId),
        where("date", ">=", startOfDayTimestamp),
        where("date", "<=", endOfDayTimestamp)
    );

    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
};

export async function updateTodayCheckinCtrlHabits(userId: string, ctrlHabits: ChecklistState): Promise<void> {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize the date to the start of the day.

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const checkinsRef = collection(db, "checkins");
    const q = query(
        checkinsRef,
        where("userId", "==", userId),
        where("date", ">=", Timestamp.fromDate(today)),
        where("date", "<", Timestamp.fromDate(tomorrow))
    );

    try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            // Assuming only one checkin per day per user, we update the first found.
            const docRef = doc(db, "checkins", querySnapshot.docs[0].id);
            await updateDoc(docRef, {
                ctrl_habits: ctrlHabits
            });
        } else {
            // Optionally create a new checkin if not found and you require it
            // This line is commented out because you specifically asked to only update existing records
            // await setDoc(doc(checkinsRef), { userId, ctrl_habits: ctrlHabits, date: Timestamp.fromDate(new Date()) });
        }
    } catch (error) {
        console.error("Error updating ctrl_habits for today's checkin:", error);
    }
}
