import GoogleIcon from '@mui/icons-material/Google'; // Correct import for Google icon from MUI
import { Box, Container, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { signInWithPopup } from 'firebase/auth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from '../../firebase'; // Ensure these exports are correctly typed in your project

const Login: React.FC = () => {
  const navigate = useNavigate(); // `useNavigate` hook is properly typed by 'react-router-dom'

  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // After successful login, navigate to the dashboard
        navigate('/dashboard');
      })
      .catch((error: any) => { // Use a more specific type if known
        console.error(error);
      });
  };

  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh" // This ensures the Box takes the full viewport height
  >
    <Container maxWidth="sm">
      <Stack justifyContent="center" alignItems="center" direction="row" useFlexGap flexWrap="wrap">
        <Container
          component="img"
          alt="logo"
          sx={{
            maxWidth: '80%'
          }}
          src="logo_CRT_effect.jpg"
        />
        <Button
          color='secondary'
          onClick={handleLogin}
          startIcon={<GoogleIcon />}
        >
          Google SignIn
        </Button>
      </Stack>
    </Container>
  </Box>
   
  );
};

export default Login;
