import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, CardContent, Container, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { NutritionInfo } from '../../types/nutrition';



interface NutritionInfoEditorProps {
    initialNutritionInfo: NutritionInfo[];
    onNutritionInfoChange: (nutritionInfo: NutritionInfo[]) => void;
}

const NutritionInfoEditor: React.FC<NutritionInfoEditorProps> = ({ initialNutritionInfo, onNutritionInfoChange }) => {
    const [nutritionInfo, setNutritionInfo] = useState<NutritionInfo[]>(initialNutritionInfo);


    useEffect(() => {
        setNutritionInfo(initialNutritionInfo);
      
    }, [initialNutritionInfo]);



    useEffect(() => {
      onNutritionInfoChange(nutritionInfo);
    }, [nutritionInfo]);



    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      const updatedNut =  nutritionInfo.map((item, idx) => {
        if (idx === index) {
            if (['calories', 'protein', 'fat', 'carbs'].includes(name)) {
                return { ...item, [name]: parseInt(value, 10) };
            } else if (name === 'key_ingredients') {
                return { ...item, [name]: value.split(',').map(ing => ing.trim()) };
            }
            return { ...item, [name]: value };
        }
        return item;
    });
      setNutritionInfo(updatedNut);
    };

    


    const handleAddNutritionInfo = () => {
        const newInfo: NutritionInfo = {
            id: Date.now().toString(),
            description: '',
            calories: 0,
            protein: 0,
            fat: 0,
            carbs: 0,
            caffeine_mg: 0,
            liters_water: 0,
            alcohol: 0,
            key_ingredients: []
        };
        setNutritionInfo([...nutritionInfo, newInfo]);
    };

    const handleDeleteNutritionInfo = (id: string) => {
        setNutritionInfo(nutritionInfo.filter(info => info.id !== id));
    };

    return (
      <Container sx={{ p: 2 }}>
      <Box component="form" noValidate onSubmit={(e) => e.preventDefault()}>
        {nutritionInfo.map((info, index) => (
          <Card key={info.id || index} sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Nutrition Info #{index + 1}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    name="description"
                    value={info.description}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Calories"
                    variant="outlined"
                    name="calories"
                    type="number"
                    value={info.calories}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Protein (g)"
                    variant="outlined"
                    name="protein"
                    type="number"
                    value={info.protein}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Fat (g)"
                    variant="outlined"
                    name="fat"
                    type="number"
                    value={info.fat}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Carbs (g)"
                    variant="outlined"
                    name="carbs"
                    type="number"
                    value={info.carbs}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <TextField
                    label="Key Ingredients"
                    variant="outlined"
                    name="key_ingredients"
                    value={info.key_ingredients.join(', ')}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => handleDeleteNutritionInfo(info.id || "")} color="error">
                    <DeleteIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button onClick={handleAddNutritionInfo} startIcon={<AddBoxIcon />}>
          Add Nutrition Info
        </Button>
      </Box>
    </Container>
    );
};



interface NutritionInfoViewerProps {
  initialNutritionInfo: NutritionInfo[];
}

// Functional component to display a list of nutrition information
const NutritionInfoViewer: React.FC<NutritionInfoViewerProps> = ({ initialNutritionInfo }) => {
    return (
        <Container >
            <Box   sx={{ marginTop: 2 }}>
                {initialNutritionInfo.map((info, index) => (
                    <Paper variant="outlined" key={info.id || index} elevation={2} sx={{ margin: 2, padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {info.description}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="subtitle1">Calories: {info.calories}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="subtitle1">Protein (g): {info.protein}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="subtitle1">Fat (g): {info.fat}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="subtitle1">Carbs (g): {info.carbs}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Key Ingredients: {info.key_ingredients.join(', ')}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Box>
        </Container>
    );
};


export { NutritionInfoEditor, NutritionInfoViewer };

