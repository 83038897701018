import { collection, addDoc, Timestamp, updateDoc, deleteDoc, getDoc, doc, getDocs, query, where, DocumentReference, orderBy} from "firebase/firestore";
import { db } from "../firebase";
import { Cycle } from "../types/utils";


// Create a Cycle
export async function createCycle(cycle: Cycle) {
    try {
      const docRef: DocumentReference = await addDoc(collection(db, "cycles"), {
        ...cycle,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      cycle.id = docRef.id;
      return cycle;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  // Read a Cycle
export async function getCycle(cycleId: string): Promise<Cycle | undefined> {
    const cycleRef = doc(db, "cycles", cycleId);
    const cycleSnap = await getDoc(cycleRef);
    if (cycleSnap.exists()) {
      return cycleSnap.data() as Cycle;
    } else {
      console.error("No such cycle!");
      return undefined;
    }
  }
  
  // Read all Cycles created by a specific user
  export async function getCycles(createdBy: string): Promise<Cycle[]> {
    const q = query(collection(db, "cycles"), where("createdBy", "==", createdBy));
    const querySnapshot = await getDocs(q);
    const fetchedEntries: Cycle[] = [];
    querySnapshot.forEach(doc => {
      let cycle = doc.data() as Cycle;
      cycle.id = doc.id;
      fetchedEntries.push({
        ...cycle
      });
    });
    return fetchedEntries;
  }

  
  // Update a Cycle
export async function updateCycle(cycleId: string, updates: Cycle) {
    const cycleRef = doc(db, "cycles", cycleId);
    await updateDoc(cycleRef, {
      ...updates,
      updatedAt: Timestamp.now()
    });
  }

  // Delete a Cycle
export async function deleteCycle(cycleId: string) {
    const cycleRef = doc(db, "cycles", cycleId);
    await deleteDoc(cycleRef);
  }

  