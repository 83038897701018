import React, { useEffect, useRef, useState } from 'react';
import { MadLibsInputItem } from '../../../types/utils';
import { getMadlibs } from '../../../functions/utils';
import { Button, CircularProgress, Container, TextField, Typography } from '@mui/material';




const MadLibs: React.FC = () => {
  const [placeholders, setPlaceholders] = useState<MadLibsInputItem[]>([]);
  const [template, setTemplate] = useState<string>("")
  const [inputs, setInputs] = useState<{[key: number]: string}>({});
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [finalStory, setFinalStory] = useState<string>('');
  const fetchedRef = useRef<boolean>(false);

  useEffect(() => {
     if (!fetchedRef.current) {
      fetchTemplate();
      fetchedRef.current = true;
    }
  }, []);


  const fetchTemplate = async () => {
    setLoading(true)
    const temp = await getMadlibs();
    parseTemplate(temp);
    setLoading(false)
  };

  const parseTemplate = (temp: string) => {
    // Example: parse and convert "Today I saw a [noun] and it was [adj]."
    // into an array of placeholders
    const regex = /\[(.*?)\]/g;
    let result;
    const placeholders: MadLibsInputItem[] = [];
    let id = 0;
    while ((result = regex.exec(temp)) !== null) {
      placeholders.push({ id: id++, pos: result[1], value: '' });
    }
    setPlaceholders(placeholders);
    setTemplate(temp)
  };

  const handleChange = (id: number, value: string) => {
    setInputs({
      ...inputs,
      [id]: value
    });
  };

  const handleSubmit = () => {
    let story = template
    placeholders.forEach(placeholder => {
       story = story.replace(`[${placeholder.pos}]`, inputs[placeholder.id]);
    });
    setFinished(true)
    setFinalStory(story);
  };

  const resetGame = () => {
    setInputs({});
    setFinalStory('');
    setTemplate("")
    setFinished(false)
    fetchTemplate()
  };


  return (   <Container  sx={{
    display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',  // Change from 'space-between' to 'center'
  height: '100vh',  // Ensure the container takes full viewport height
  maxWidth: '800px',
  m: '0 auto',  // Automatically margin for horizontal centering
  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  position: 'relative',
  padding: '20px'  // Add some padding for spacing
  }}>
    {loading ? (
      <CircularProgress color="success" />
    ) : (
      <Container maxWidth="sm">
        <form >
          {placeholders.map((placeholder) => (
            <TextField
              key={placeholder.id}
              label={placeholder.pos.charAt(0).toUpperCase() + placeholder.pos.slice(1)}
              variant="outlined"
              margin="normal"
              fullWidth
              value={inputs[placeholder.id] || ''}
              onChange={(e) => handleChange(placeholder.id, e.target.value)}
            />
          ))}
          <Button onClick={handleSubmit}  color="primary" fullWidth>
            Complete the Story
          </Button>
          {finished ?   ( <Button  onClick={resetGame} color="error" fullWidth>
            Reset Game
          </Button>) : <p></p>}
        </form>
        {finalStory && <Typography variant="h6" gutterBottom>{finalStory}</Typography>}
      </Container>
    )}
  </Container>
  );
};

export default MadLibs;
