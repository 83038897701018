// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/utils/style/theme';  // Make sure to import your custom MUI theme
import { Container, CssBaseline, Box } from '@mui/material';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/pages/Login';
import { Navbar } from './components/pages/navbar';
import UserProfile from './components/user/profile';
import { Meals } from './components/fitness/meals';
import Dash from './components/pages/dash';
import Fitness from './components/fitness/fitness';
import Games from './components/games/games';
import { CycleList } from './components/cycles/cycles';
import { CheckinList } from './components/planner/check-in';
import { PlanPanel } from './components/planner/planner';
const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box >
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <Navigate to="/" />
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Dash />
                  </PrivateRoute>
                }
              />
              <Route
                path="/fitness"
                element={
                  <PrivateRoute>
                    <Fitness />
                  </PrivateRoute>
                }

              />
              <Route
                path="/nutrition"
                element={
                  <PrivateRoute>
                    <Meals />
                  </PrivateRoute>
                }

              />
              <Route
                path="/games"
                element={
                  <PrivateRoute>
                    <Games />
                  </PrivateRoute>
                }

              />
              <Route
                path="/checkin"
                element={
                  <PrivateRoute>
                    <CheckinList />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />
              
              <Route
                path="/cycle"
                element={
                  <PrivateRoute>
                    <CycleList />
                  </PrivateRoute>
                }
              />
                 <Route
                path="/planner"
                element={
                  <PrivateRoute>
                    <PlanPanel />
                  </PrivateRoute>
                }
              />
            </Routes>
            <Navbar />
          </Box>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
