import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, Timestamp, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { Task } from "../types/planner";



  export const addTask = async (task: Task, user: string) => {
    try {
     const docRef = await addDoc(collection(db, "tasks"),{ 
        ...task,
        userId: user,
        dueDate: task.dueDate || null,
        createdAt: Timestamp.now(),
      });

      return docRef.id;
    } catch (error) {
      console.error('Error adding task: ', error);
    }
  };

  

  export const toggleTaskCompletion = async (taskId: string, status: boolean) => {
    try {

      const programRef = doc(db, "tasks", taskId);
      await updateDoc(programRef, { completed: status  });
    } catch (error) {
      console.error('Error toggling task completion: ', error);
    }
  };

  export const deleteTask = async (taskId: string) => {
    try {
      const docRef = doc(collection(db, "tasks"),taskId)
        await  deleteDoc(docRef);

    } catch (error) {
      console.error('Error deleting task: ', error);
    }
  };
  

  export const updateTask = async (task: Task, user: string) => {
    try {
      if (task.id == undefined){
        console.error("task id is undefined")
        return
      }
      const taskRef = doc(db, "tasks", task.id);// Assuming `task.id` contains the document ID
      await updateDoc(taskRef, {
        ...task,
        dueDate: task.dueDate || null,
        createdAt: task.createdAt, // Preserving the original creation date
      });
    } catch (error) {
      console.error('Error updating task: ', error);
    }
  };

  
  export const markTaskAsCompleted = async (taskId: string) => {

    const docRef = doc(db, "tasks", taskId);
    await updateDoc(docRef, { completed: true });
  };
  
  export const getTasks = async (userId: string): Promise<Task[]> => {
    const q = query(collection(db, "tasks"), where("userId", "==", userId) );
    const querySnapshot = await getDocs(q);
    const fetchedEntries: Task[] = [];
    querySnapshot.forEach(doc => {
      let task = doc.data() as Task
      task.id = doc.id
      fetchedEntries.push({
        ...task
      });
    });
    return fetchedEntries
  };

  export const getTodaysTasks = async (userId: string): Promise<Task[]> => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

    const startOfDayTimestamp = Timestamp.fromDate(startOfDay);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDay);

    const q = query(collection(db, "tasks"), 
    where("userId", "==", userId) ,
    where("createdAt", "<=", endOfDayTimestamp),
    where("createdAt", ">=", startOfDayTimestamp),
  );
    const querySnapshot = await getDocs(q);
    const fetchedEntries: Task[] = [];
    querySnapshot.forEach(doc => {
      let program = doc.data() as Task
      program.id = doc.id
      fetchedEntries.push({
        ...program
      });
    });
    return fetchedEntries
  };

  export const getIncTasks = async (userId: string): Promise<Task[]> => {

    const q = query(collection(db, "tasks"), 
    where("userId", "==", userId) ,
    where("completed", "==", false) , orderBy( "createdAt","desc")
  );
    const querySnapshot = await getDocs(q);
    const fetchedEntries: Task[] = [];
    querySnapshot.forEach(doc => {
      let program = doc.data() as Task
      program.id = doc.id
      fetchedEntries.push({
        ...program
      });
    });
    return fetchedEntries
  };