import React, { useEffect, useState, ChangeEvent } from 'react';
import { Container, Button, TextField, Typography, Box, FormControl, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { WorkoutExercise } from '../../types/fitness';
import TimerDisplay from '../utils/timedisplay';
interface ExerciseEditorProps {
  workoutId: string | "";
  initial_exercises: WorkoutExercise[]
  onExercisesChange: (exercises: WorkoutExercise[]) => void;

}

const ExerciseEditor: React.FC<ExerciseEditorProps> = ({ workoutId, initial_exercises, onExercisesChange }) => {
  const [exercises, setExercises] = useState<WorkoutExercise[]>([]);


  useEffect(() => {
    if (initial_exercises != null) {
      setExercises(initial_exercises);
    }

  }, [initial_exercises]);

  // Effect to notify parent component when exercises change
  useEffect(() => {
    onExercisesChange(exercises);


  }, [exercises, onExercisesChange]);



  const handleSubmit = () => {
    onExercisesChange(exercises);



    // Post to backend or further process the `exercises` array
  };



  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const updatedExercises = exercises.map((ex, idx) => idx === index ? { ...ex, [name]: name === 'reps' || name === 'sets' || name === 'weight' || name === 'duration' || name === 'order' ? parseInt(value, 10) : value } : ex);
    setExercises(updatedExercises);
    onExercisesChange(exercises);

  };

  const handleAddExercise = () => {

    const newExercise: WorkoutExercise = {
      id: Date.now().toString(),
      workoutId: workoutId,
      exercise: '',
      reps: 0,
      sets: 0,
      duration: 0,
      weight: 0,
      currentSets: 0,
      order: exercises.length
    };
    setExercises(exercises.concat(newExercise));

    setExercises([...exercises, { ...newExercise, id: Math.random().toString(36).substr(2, 9) }]);

  };



  const handleDeleteExercise = (id: string) => {
    setExercises(exercises.filter(ex => ex.id !== id));

  };





  return (
    <Container sx={{pt:5, pb:5}}>


      <Box component="form" noValidate onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>

        {exercises && exercises.map((ex, index) => (
          <Grid container spacing={1} key={ex.id}> {/* Reduced spacing */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Exercise Name"
                variant="outlined"
                name="exercise"
                value={ex.exercise}
                onChange={(e) => handleChange(index, e)}
                fullWidth
                size="small" // Makes the TextField smaller
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                label="Reps"
                variant="outlined"
                name="reps"
                type="number"
                value={ex.reps}
                onChange={(e) => handleChange(index, e)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                label="Sets"
                variant="outlined"
                name="sets"
                type="number"
                value={ex.sets}
                onChange={(e) => handleChange(index, e)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                label="Weight"
                variant="outlined"
                name="weight"
                type="number"
                value={ex.weight}
                onChange={(e) => handleChange(index, e)}
                fullWidth
                size="small"
              />
            </Grid>
          
             
                  <Grid item xs={4} sm={2}>
                  <TextField
                    label="Duration (s)"
                    variant="outlined"
                    name="duration"
                    type="number"
                    value={ex.duration}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    size="small"
                  />
                    </Grid>
                   
                  {ex.duration > 0 && ( 
                    
                    <Grid>
                       <TimerDisplay initialSeconds={ex.duration}/>
                    </Grid>
                   
                  )}
         
            <Grid  item xs={4} sm={2}>
              <IconButton onClick={() => handleDeleteExercise(ex.id)} color="error" >
                <DeleteIcon  color='error' />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Container>
        <IconButton onClick={() => handleAddExercise()} aria-label="add" color="warning" >
          <AddBoxIcon /> Add
        </IconButton>

      </Container>
    </Container>
  );
};



export { ExerciseEditor };
