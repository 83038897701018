import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { ContentItem } from "../../types/timeline"; // Adjust the import path as necessary
import ExpandableTypography from '../utils/expandableTypography';

interface ContentDisplayProps {
  items: ContentItem[];
}

const ContentDisplay: React.FC<ContentDisplayProps> = ({ items }) => {
  const renderItem = (item: ContentItem) => {
    switch (item.type) {
      case 'image':
        return (
          <Box key={item.url} style={{ marginBottom: '20px' }}>
            <Link underline="hover" href={item.url} target="_blank" rel="noopener noreferrer">
              <img src={item.url} alt={item.altText || 'Image'} style={{ maxWidth: '100%' }} />
            </Link>
            {item.description && <ExpandableTypography text={item.description} />}
          </Box>
        );
      case 'video':
        return (
          <Box key={item.url} style={{ marginBottom: '20px' }}>
            <video controls style={{ maxWidth: '100%' }}>
              <source src={item.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {item.duration && <Typography color="success">Duration: {item.duration} seconds</Typography>}
          </Box>
        );
      case 'youtube':
        return (
          <Box key={item.url} style={{ marginBottom: '20px' }}>
            <iframe
              src={item.url}
              title={'YouTube video'}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ width: '100%', height: '360px' }}
            ></iframe>
            {item.description && <ExpandableTypography text={item.description} />}
          </Box>
        );
      case 'yt-playlist':
        return (
          <Box key={item.url} style={{ marginBottom: '20px' }}>
            <iframe
              src={item.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{ width: '100%', height: '360px' }}
            ></iframe>
          </Box>
        );
      case 'link':
        return (
          <Box key={item.url} style={{ marginBottom: '20px' }}>
            {item.previewImage ? (
              <Box>
                <Link underline="hover" href={item.url} target="_blank" rel="noopener noreferrer">
                  <img src={item.previewImage} alt={item.title || 'Link preview'} style={{ maxWidth: '100%' }} />
               
                </Link>
         
              </Box>
            ) : (
              <Link underline="hover" href={item.url} target="_blank" rel="noopener noreferrer">
                <Typography color="warning">{item.title ? item.title : item.url} </Typography>
              </Link>
            )}
          </Box>
        );
      case 'other':
        return (
          <Box key={item.url || Math.random()} style={{ marginBottom: '20px' }}>
            <Typography color="white">{item.content}</Typography>
            {item.url && (
              <Link color="error" underline="hover" href={item.url} target="_blank" rel="noopener noreferrer">
                More info
              </Link>
            )}
          </Box>
        );
      default:
        return <Typography variant="body2" color="white">Unsupported content type</Typography>;
    }
  };

  return (
    <Box>
      {items.map(renderItem)}
    </Box>
  );
};

export default ContentDisplay;
