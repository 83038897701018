import { darkScrollbar } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000', // Black background
      paper: '#000000 !important',   // Dark gray for paper elements
    },
    primary: {
      main: '#00FFB2',     // Mint Green primary color
      contrastText: '#000000', // Black text on primary background
    },
    secondary: {
      main: '#FFFF00',    // Yellow green
      contrastText: '#000000', // Black text on mint green background
    },
    warning: {
      main: '#FFFF00',    // Yellow warning color
      contrastText: '#000000', // Ensure contrast text is black for readability
    },
    error: {
      main: '#FF1744',    // Red error color
      contrastText: '#ffffff', // White text on red background
    },
    info: {
      main: '#FFFFFF',    // White info color
      contrastText: '#000000', // Black text on white background
    },
    success: {
      main: '#00FFB2',    // Mint green for success
      contrastText: '#000000', // Black text for success background
    },
    text: {
      primary: '#ffffff', // White primary text
      secondary: '#00FFB2', // Mint green secondary text
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          margin: '5px !important',
          padding: '5px !important',
          backgroundColor: '#000000 !important', // Global background color
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none', // Removes any gradient or shadows
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000000',   // Black background for tooltip
          color: '#FFFFFF',             // White text in tooltip
          fontSize: '0.875rem',         // Adjust font size
          borderRadius: '4px',          // Rounded corners
          padding: '8px 16px',          // Padding for better spacing
        },
        arrow: {
          color: '#000000',             // Match arrow color to tooltip background
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000 !important', // Darker shade for bottom navigation
          boxShadow: '0 -4px 5px -2px #00FFB2', // Mint green shadow
        }
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000', // Darker shade for bottom navigation
          boxShadow: '0 -4px 5px -2px #00FFB2', // Mint green shadow
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000', // Black background for AppBar
          color: '#FFFF00',           // Yellow text color for AppBar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000', // Black button background
          margin: 5,
          variant: 'outlined',
          border: '2px solid ', // Mint green border
          '&:hover': {
            backgroundColor: '#121212', // Dark gray on hover
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000', // Black background for Chip
          margin: 4,
          boxShadow: '-2px -1px 0 0 #FFFFFF', // White shadow for Chip
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: 5,
          '& .MuiInputBase-root': {
            color: '#FFFFFF', // White text color in TextField
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00FFB2', // Mint green border color
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FFFF00', // Yellow border color on hover
          },
          '& .MuiInputLabel-root': {
            color: '#00FFB2', // Mint green label color
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    button: {
      textTransform: 'none', // Keep button text as is, without uppercase transformation
    },
  },
});


export default theme;