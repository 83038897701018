import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Container } from '@mui/material';

const steps = [
  {
    label: 'Find 5 things you can see',
    inputs: Array(5).fill(''),
    description: 'Look around you and identify five things you can see.',
  },
  {
    label: 'Find 4 things you can touch',
    inputs: Array(4).fill(''),
    description: 'Touch and feel four different objects around you.',
  },
  {
    label: 'Find 3 things you can hear',
    inputs: Array(3).fill(''),
    description: 'Close your eyes and list three sounds you can hear.',
  },
  {
    label: 'Find 2 things you can smell',
    inputs: Array(2).fill(''),
    description: 'Take a moment to detect two different scents.',
  },
  {
    label: 'Find 1 thing you can taste',
    inputs: Array(1).fill(''),
    description: 'Consume something and focus on the taste.',
  },
  {
    label: 'Reflection',
    inputs: Array(0),
    description: `Reflect on your experiences. Here's a thought to ponder:`,
    quote: "Waste no more time arguing what a good man should be. Be one. - Marcus Aurelius"
  }
];

export default function GroundingExerciseStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = React.useState(steps.map(step => step.inputs));

  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleChange = (index: number, event: any) => {
    const newFormValues = [...formValues];
    newFormValues[activeStep][index] = event.target.value;
    setFormValues(newFormValues);
  };

  return (
    <Container sx={{  width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={{ position: 'relative' }}>
  
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '10%',
            pl: 2,
            bgcolor: 'background.default',
            mt: 10,
            zIndex: 1,
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
      </Box>
      <Box sx={{ height: '80%', width: '100%', p: 2, overflowY: 'auto' }}>
        <Typography>{steps[activeStep].description}</Typography>
        {steps[activeStep].inputs.length > 0 && formValues[activeStep].map((value, index) => (
          <TextField
            key={index}
            label={`Input #${index + 1}`}
            variant="outlined"
            fullWidth
            value={value}
            onChange={(e) => handleChange(index, e)}
            sx={{ mt: 1 }}
          />
        ))}
        {activeStep === steps.length - 1 && <Typography sx={{ mt: 2 }}>{steps[activeStep].quote}</Typography>}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </Container>
  );
}
