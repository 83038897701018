import CloseIcon from '@mui/icons-material/Close';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, List, ListItem, ListItemText, Slide, Stack, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { GeminiPlan, GeneratePlanConetxt, checkCheckinToday, getLastCheckinByUser, updateCheckinById } from '../../functions/checkin';
import { logWorkout } from '../../functions/fitness';
import { createMeal } from '../../functions/nutrition';
import { addTask } from '../../functions/tasks';
import { WorkoutExercise } from '../../types/fitness';
import { Meal, NutritionInfo } from '../../types/nutrition';
import { Checkin, Plan } from '../../types/planner';
import { CheckinCard, CheckinForm } from './check-in';
import { MDXEditor } from '@mdxeditor/editor';
import { ReadOnlyMDXEditor } from '../utils/mardkown_render';

const Transition = React.forwardRef(function Transition(
    props: { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



interface PlanDisplayProps {
    plan: Plan;
    onCancel?: () => void;
    onAccept?: () => void;
}

const PlanDisplay: React.FC<PlanDisplayProps> = ({ plan, onCancel, onAccept }) => {
    const { workout, mealPlan, insights, tasks } = plan;

    const calculateDaysSinceCreated = (createdAt: Date) => {
        const now = new Date();
        const diff = now.getTime() - createdAt.getTime();
        return Math.floor(diff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    };


    return (
        <Box maxWidth="md">
            <Typography variant="h4" component="div" gutterBottom>
                {/* Plan for {day.toDate().toDateString()} */}
            </Typography>
            {insights && <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Insights
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {insights}
                    </Typography>
                </CardContent>
            </Card>}
            {workout && <Card>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                        Workout: {workout.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" gutterBottom>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Description: {workout.description}
                    </Typography>
                    <List>
                        {workout.exercises.map((exercise: WorkoutExercise, index: number) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`Exercise ${exercise.order}: ${exercise.exercise}`}
                                    secondary={`Reps: ${exercise.reps}, Sets: ${exercise.sets}, Weight: ${exercise.weight}, Duration: ${exercise.duration} seconds`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>}
            <Typography variant="h5" gutterBottom>
                Tasks
            </Typography>
            {tasks && <List>
                {tasks.map((task, index: number) => (

                    <ListItem key={index} style={{ textDecoration: task.completed ? 'line-through' : 'none' }}>
                        <Stack>
                            <ListItemText
                                primary={task.title}
                                secondary={`Due: ${task.dueDate ? (task.dueDate.toDate().toLocaleDateString()) : 'N/A'}, Created: ${calculateDaysSinceCreated(task.createdAt.toDate())} days ago`}
                            />
                            {task.subTasks && task.subTasks.length > 0 && (
                                <List sx={{ paddingLeft: 4 }}>
                                    {task.subTasks.map((subTask, subIndex) => (
                                        <ListItem key={subIndex} style={{ textDecoration: subTask.completed ? 'line-through' : 'none' }}>
                                            <ListItemText
                                                primary={subTask.description}
                                                secondary={subTask.completed ? 'Completed' : 'Pending'}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            )}
                            <ReadOnlyMDXEditor markdown={task.notes || ""} />

                        </Stack>

                    </ListItem>
                ))}
            </List>}
            {mealPlan.map((meal: Meal, index: number) => (
                <Card key={index} sx={{ mt: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Meal {index + 1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {/* Created by: {meal.userId} on {meal.createdAt?.toDate().toLocaleString()} */}
                        </Typography>
                        {meal.image && <img src={meal.image} alt={`Meal ${index + 1}`} style={{ maxWidth: '100%' }} />}
                        <Typography variant="body1" gutterBottom>
                            Notes: {meal.notes}
                        </Typography>

                        <List>
                            {meal.info.map((nutrition: NutritionInfo, idx: number) => (
                                <ListItem key={idx}>
                                    <ListItemText
                                        primary={nutrition.description}
                                        secondary={`Calories: ${nutrition.calories}, Protein: ${nutrition.protein}g, Fat: ${nutrition.fat}g, Carbs: ${nutrition.carbs}g`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            ))}


            <Box display="flex" justifyContent="space-between" mt={2}>
                {onCancel &&
                    <Button color="error" onClick={onCancel}>
                        Cancel
                    </Button>}
                {onAccept && <Button color="primary" onClick={onAccept}>
                    Accept
                </Button>}
            </Box>
        </Box>
    );
};




const PlanPanel: React.FC = () => {
    const { currentUser } = useAuth();
    const [open, setOpen] = useState(false);
    const [openCheckin, setCheckinOpen] = useState(false);
    const [currentCheckin, setCurrentCheckin] = useState<Checkin | null>(null);
    const [plan, setPlan] = useState<Plan | null>(null);
    const [checkedIn, setCheckin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isNoCheckinDialogOpen, setIsNoCheckinDialogOpen] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    const checkForCheckinToday = async () => {
        try {
            const isCheckedIn = await checkCheckinToday(currentUser.uid);
            setCheckin(isCheckedIn);
            if (isCheckedIn) {
                const check = await getLastCheckinByUser(currentUser.uid);
                setCurrentCheckin(check);
            }
        } catch (error) {
            console.error("Failed to check for today's check-in:", error);
            setIsErrorDialogOpen(true);
        }
    };

    useEffect(() => {
        checkForCheckinToday();
    }, [currentUser]);

    const generatePlan = async () => {
        setLoading(true);
        const isCheckedIn = await checkCheckinToday(currentUser.uid);
        if (!isCheckedIn) {
            setIsNoCheckinDialogOpen(true);
            setLoading(false);
        } else {
            setCheckin(isCheckedIn);
            if (isCheckedIn) {
                const check = await getLastCheckinByUser(currentUser.uid);
                setCurrentCheckin(check);
            }
            try {
                const ctx = await GeneratePlanConetxt(currentUser.uid);
                const newPlan: Plan = await GeminiPlan(ctx);

                newPlan.mealPlan = newPlan.mealPlan.map(meal => ({
                    ...meal,
                    userId: currentUser.uid,
                }));
                setPlan(newPlan);
                setOpen(true);
                setLoading(false);
            } catch (error) {
                console.error("Something went wrong while generating the plan:", error);
                setIsErrorDialogOpen(true);
                setLoading(false);
            }
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleCheckinCancel = async () => {
        await checkForCheckinToday();
        setCheckinOpen(false);
    };

    const handleCheckin = () => {
        setCheckinOpen(true);
    };

    const handleAccept = async () => {
        if (plan) {
            plan.workout.createdBy = currentUser.uid;
            if (currentCheckin && currentCheckin.id) {
                currentCheckin.plan = plan;
                await updateCheckinById(currentCheckin.id, currentCheckin);
            }
            await logWorkout({ workout: plan.workout, userId: currentUser.uid });
            plan.mealPlan.forEach(async meal => {
                meal.createdAt = Timestamp.now();
                await createMeal(meal);
            });
            plan.tasks.forEach(async task => {
                await addTask(task, currentUser.uid);
            });
        }
        setOpen(false);
    };

    return (
        <Box>
            <Button disabled={checkedIn} onClick={handleCheckin}>
                {checkedIn ? "Already Checked In" : "Checkin"}
            </Button>
            {loading ? <LinearProgress /> : (
                <Button
                    startIcon={<PrecisionManufacturingIcon color="warning" />}
                    disabled={!currentCheckin}
                    onClick={generatePlan}
                >
                    {!currentCheckin?.plan?.day ? "Generate Plan" : "Re-Generate Plan"}
                </Button>
            )}
            {currentCheckin && (
                <Box>
                    <CheckinCard key={currentCheckin.id} checkin={currentCheckin} />
                    <Button size="small" color='warning' onClick={handleCheckin}>
                        Edit Checkin
                    </Button>
                </Box>
            )}
            <Dialog fullScreen open={open} onClose={handleCancel} TransitionComponent={Transition}>
                <IconButton edge="start" color="inherit" onClick={handleCancel} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6">
                    <PrecisionManufacturingIcon color="warning" />   Plan
                </Typography>
                {plan && <PlanDisplay plan={plan} onCancel={handleCancel} onAccept={handleAccept} />}
            </Dialog>

            <Dialog fullScreen open={openCheckin} onClose={handleCheckinCancel} TransitionComponent={Transition}>
                <CheckinForm checkin={currentCheckin} userId={currentUser.uid} onClose={handleCheckinCancel} />
            </Dialog>

            {/* No Checkin Found Dialog */}
            <Dialog open={isNoCheckinDialogOpen} onClose={() => setIsNoCheckinDialogOpen(false)}>
                <DialogTitle>No Checkin Found</DialogTitle>
                <DialogContent>
                    <Typography>No check-in was found for today. Please complete a check-in before generating a plan.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsNoCheckinDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Dialog */}
            <Dialog open={isErrorDialogOpen} onClose={() => setIsErrorDialogOpen(false)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <Typography>Something went wrong. Please try again later.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsErrorDialogOpen(false)} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export { PlanDisplay, PlanPanel };
