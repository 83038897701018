import React from 'react';
import { auth } from '../../firebase';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';

const SignOut = () => {
  const signOut = () => {
    auth.signOut();
  };

  return (
    <Button  color="error" onClick={signOut} startIcon={<LogoutIcon />}>
  Logout
</Button>
  );
};

export default SignOut;