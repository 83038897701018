import { Checkbox, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChecklistState } from '../../types/fitness';


// Define the structure for the checklist state


const DailyChecklist: React.FC<ChecklistState> = ({ weighIn, workout, stepsGoal, floorGoal, proteinIntake, sleepWell }) => {
    const [checklist, setChecklist] = useState<ChecklistState>({
      weighIn,
      workout,
      stepsGoal,
      floorGoal,
      proteinIntake,
      sleepWell
    });
  
    useEffect(() => {
      setChecklist({ weighIn, workout, stepsGoal, floorGoal, proteinIntake, sleepWell });

    }, [weighIn, workout, stepsGoal, floorGoal, proteinIntake, sleepWell]); // Update state when props change
  
  return (
    <List>
      <Typography variant="h6" sx={{ my: 2 }}>
        Daily Checklist
      </Typography>
      {Object.entries(checklist).map(([key, done ]) => (
        <ListItem key={key} dense>
          <ListItemIcon>
            <Checkbox edge="start" checked={done} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText primary={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} />
        </ListItem>
      ))}
    </List>
  );
};

export default DailyChecklist;
