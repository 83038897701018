import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PageAppBar } from '../pages/navbar';
import { CustomTabPanel } from '../utils/style/tabpanel';
import DailyData from './daily';
import { Meals } from './meals';
import { CreateUpdateWorkoutProgram, ListWorkoutPrograms } from './workoutProgram'; // Make sure this is the correct import path
import WorkoutLog from './workout_log';



function WorkoutProgramManagement() {
    const [isProgramModalOpen, setProgramModalOpen] = useState(false);
    const [isWorkoutModalOpen, setWorkoutModalOpen] = useState(false);

    const handleOpenProgramModal = () => {
        setProgramModalOpen(true);
    };

    const handleCloseProgramModal = () => {
        setProgramModalOpen(false);
    };

    const handleOpenWorkoutModal = () => {
        setWorkoutModalOpen(true);
    };

    const handleCloseWorkoutModal = () => {
        setWorkoutModalOpen(false);
    };

    return (
        
     <Box sx={{width:'100%', p: 0, m:0 }}>
     
            <Typography variant="h6" gutterBottom>
                Workout Program Management
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button  onClick={handleOpenProgramModal}>
Add Program
              </Button>
            </Grid>
                <Grid item xs={12}>
                    <CreateUpdateWorkoutProgram 
                        open={isProgramModalOpen} 
                        onClose={handleCloseProgramModal} 
                    />
                    <ListWorkoutPrograms />
                </Grid>
           
            </Grid>

            </Box>
    );
}

function Fitness() {
    const [value, setValue] = React.useState(0);



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }




    return(
        
        <Box  sx={{   mt: 7 ,  bp: 7, mb: 10 }} >
             <PageAppBar pageTitle="Gym"/>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={value} onChange={handleChange}   aria-label="basic tabs example">
            <Tab label="Daily" {...a11yProps(0)} />
            <Tab label="Nutrition" {...a11yProps(1)} />
            <Tab label="Log" {...a11yProps(2)} />
            <Tab label="Manager" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
         <DailyData/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
         <Meals />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
        <WorkoutLog/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
        <WorkoutProgramManagement/>
        </CustomTabPanel>
      </Box>
    );
    
}

export default Fitness;
