import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import CoachingSettingsComponent from '../coaching/coaching';
import { Box, Card, CardActions, CardContent, Container, Stack, Typography } from '@mui/material';
import SignOut from './logout';
import { PageAppBar } from '../pages/navbar';


const UserProfile : React.FC = () => {
  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Box>Please sign in.</Box>;
  }

  return  (
    <Box >
    <PageAppBar pageTitle="User Profile"/>
    <Stack spacing={3}>
  <Container >
  <Card variant="outlined" sx={{ mt: 4 }}>
    <CardContent>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <Typography variant="subtitle1">
        Name: {currentUser.displayName}
      </Typography>
      <Typography variant="subtitle1">
        Email: {currentUser.email}
      </Typography>
    </CardContent>
    <CardActions>
      <SignOut />
    </CardActions>
  </Card>
  </Container>
  <Container>
  <CoachingSettingsComponent />
</Container>
</Stack>
</Box>
);
};

export default UserProfile;