import { Badge, Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from '../../contexts/AuthContext';
import { getIncTasks } from "../../functions/tasks";
import { Task } from "../../types/planner";
import { TodoApp } from "../planner/tasks";
import Timeline from "../timeline/timeline";
import MSNewsWidget from "../utils/mediastack";
import { PageAppBar } from "./navbar";

import TaskAltIcon from '@mui/icons-material/TaskAlt';

function Dash() {
  const [today_tasks, settasks] = useState<Task[]>([])
  const { currentUser } = useAuth();
  const [tabIndex, setTabIndex] = useState(0); 

  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    loadTasks()
  }, [currentUser, tabIndex ]);



  const loadTasks = async () => {
    const t = await getIncTasks(currentUser.uid)
    settasks(t)
  }

  return (

    <Box sx={{ mb: 10 }} >
      <PageAppBar pageTitle="Dash" />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="icon label tabs example">
  
          <Tab label="Timeline"/>
          <Tab label="News" />
          <Tab label="Action"    icon={
          <Badge badgeContent={today_tasks.length} color="error">
              <TaskAltIcon color="action" />
          </Badge>
        }/>
        </Tabs>
      </Box>
      {tabIndex === 0 && (
        <Timeline />
      )}
       {tabIndex === 1 && (
              <MSNewsWidget />
      )}
       {tabIndex === 2 && (
        <TodoApp />
      )}


    </Box>
  );

}

export default Dash;
