import MovingIcon from '@mui/icons-material/DirectionsRun';
import CaloriesIcon from '@mui/icons-material/LocalFireDepartment'; // Example, adjust based on available icons
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Box, Card, CardContent, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface FitnessFeedbackProps {
    projectedBurn: number;
    targetBurn: number;
    currentActivityBurn: number;
    totalCalories: number;
    totalProtein: number;
    totalCarbs: number;
    targetProtein: number;
    totalFat: number;
}

const FitnessFeedback: React.FC<FitnessFeedbackProps> = ({
    projectedBurn,
    targetBurn,
    currentActivityBurn,
    totalCalories,
    totalProtein,
    totalCarbs,
    targetProtein,
    totalFat,
}) => {
    const totalBurn = currentActivityBurn + projectedBurn;
    const deficit = totalCalories < totalBurn;
    const fuelDiff = Math.abs(totalCalories - totalBurn);
    const moveDiff = Math.abs((targetBurn + projectedBurn)- totalBurn);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Determine the color of the progress bar based on intake
    const getProgressBarColor = (value: number, target: number) => {
        const percentage = (value / target) * 100;
        if (percentage < 50) return 'error'; // Red
        if (percentage < 75) return 'warning'; // Yellow
        return 'success'; // Green
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Daily Summary
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'} mb={2}>
                    <CaloriesIcon color={deficit ? "error" : "success"} />
                    <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'inherit' }}>
                        {deficit ? `${fuelDiff} Caloric Deficit` : `${fuelDiff} Caloric Surplus`} - {deficit ? `This will result in a decrease in mass. Eating more will reduce the amount of mass you lose. This is based off your RMR and your current energy expend of ${totalBurn}kCal. If this is not your desired result, relax and eat more.` : `You have eaten over your current caloric expend. This may result in an increase in mass. If this is not the desired result, consider moving more. This is based off your RMR and your current energy expend of ${totalBurn}kCal.`}
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'} mb={2}>
                    <MovingIcon color={totalBurn < (targetBurn + projectedBurn) ? "error" : "success"} />
                    <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'inherit' }}>
                        {totalBurn < (targetBurn + projectedBurn) ? `Burn ${moveDiff} more calories to maintain your current average of ${(targetBurn + projectedBurn)}.` : `Activity goal achieved with a surplus of ${Math.abs(moveDiff)}.`}
                    </Typography>
                </Box>
                {/* Active Cal Progress */}
                <Box mb={2}>
                    <Typography variant="body2" gutterBottom>
                         Active vs Historical Average
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={(currentActivityBurn / targetBurn) * 100}
                        color={getProgressBarColor(currentActivityBurn, targetBurn)}
                    />
                    <Typography variant="caption" display="block">
                        {currentActivityBurn} cals / {targetBurn} cals
                    </Typography>
                </Box>


                {/* Calories Progress */}
                <Box mb={2}>
                    <Typography variant="body2" gutterBottom>
                        Calories Eaten vs. Burned
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={(totalCalories / totalBurn) * 100}
                        color={getProgressBarColor(totalCalories, totalBurn)}
                    />
                    <Typography variant="caption" display="block">
                        {totalCalories} / {totalBurn} kcal
                    </Typography>
                </Box>

                {/* Protein Progress */}
                <Box mb={2}>
                    <Typography variant="body2" gutterBottom>
                        Protein Consumed vs. Recommended
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={(totalProtein / targetProtein) * 100}
                        color={getProgressBarColor(totalProtein, targetProtein)}
                    />
                    <Typography variant="caption" display="block">
                        {totalProtein}g / {targetProtein}g protein
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection={isMobile ? 'column' : 'row'} mb={2}>
                    <RestaurantIcon />
                    <Typography variant="body1" sx={{ textAlign: isMobile ? 'center' : 'inherit' }}>
                        Macros - Cals: {totalCalories}g, Proteins: {totalProtein}g, Carbs: {totalCarbs}g, Fats: {totalFat}g
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FitnessFeedback;
