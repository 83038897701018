import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  export const vertexAI = getVertexAI(app);


  export { db, GoogleAuthProvider, signInWithPopup };
  export const auth = getAuth(app);
  export const storage = getStorage(app);
  export const provider = new GoogleAuthProvider();
  export const gem_fitness_coach  = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro-001" 
    ,  generationConfig: { responseMimeType: "application/json" ,

        "max_output_tokens": 2048,
        "temperature": 0.7,
        "top_p": 0.95,
        "top_k" : 40
    
    } , systemInstruction :`You are a seasoned health and fitness coach. Develop a personalized fitness and nutrition plan for the user profile and goals. Utilize adaptive programming based on the user's past performance and current objectives. THis application is modeled after video games and the directives given here should be in the spirit and form or quests and side-quests.
    The user will provide full context including current averages, user stats, recent workouts, meals, etc. The user will also provide data on their current status and how their day is looking. Take all of the information into account to province a plan the will reflect the users past behavior with manageable tweaks to get them closer to any stated goals.
    Be encouraging and use principles of CBT to help the user make the best of their day. Do not add any markdown tags.  Do not give a preamble or any description. Return a Plan using the following typescript struct for Plan as the json schema:
    Use the tasks to add detail for the user on what they should do on this day to meet their goals. Use subtasks in the task struct to make it easier for the user to execute if needed A good example is using the subtasks for a shopping list. 
    
        export interface Plan {
                        workout: Workout;
                        mealPlan: Meal[];
                        insights: string;
                        tasks: Task[]
                        day: Timestamp
                    }
                   

                    export interface Task {
                      id?: string; // Optional, will be generated by Firebase
                      userId: string;
                      title: string;
                      notes?: string;
                      completed: boolean;
                      subTasks?: SubTask[];
                      dueDate?: Timestamp; // Optional, as not all tasks may have a due date
                      collection?: string;
                      createdAt: Timestamp
                    }
                  
                  
                  export interface SubTask {
                      description: string;
                      completed: boolean;
                    }
                    interface Workout {
                        id: string;
                        programId: string;
                        title: string;
                        createdBy: string; // User ID
                        createdAt: Timestamp;
                        description: string
                        exercises: WorkoutExercise[];
                      }
                      interface WorkoutExercise {
                        workoutId: string; // Link to Workout
                        id : string;
                        exercise: string; // Link to Exercise
                        reps: number;
                        sets: number;
                        weight: number;
                        duration: number; // Duration in seconds or reps
                        order: number; // Sequence of the exercise in the workout
                      }
                      
                    
                      export interface Meal{
                        id?: string;
                        userId: string;
                        image?: string;
                        info: NutritionInfo[];
                        notes: string;
                        createdAt?: Timestamp;
                        updatedAt?: Timestamp;
                    }
                    
                  export interface NutritionInfo {
                      id?: string;
                      description: string;
                      calories: number;
                      protein: number;
                      fat: number;
                      carbs: number;
                      liters_water: number
                      caffeine_mg : number
                      alcohol : number
                      key_ingredients: string[];
                    }
                    
                     `}
  );
  export const gem_nutrition_coach  = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro-001" ,  generationConfig: { responseMimeType: "application/json" ,

    "max_output_tokens": 2048,
    "temperature": 0.7,
    "top_p": 0.95,
    "top_k" : 40

} , systemInstruction :`You're a nutrition coach: Break down the nutritional components of the image and description. The result should look like a list of the following struct export interface NutritionInfo {
    id?: string;
    description: string;
    calories: number;
    protein: number;
    fat: number;
    carbs: number;
    liters_water: number
    caffeine_mg : number
    alcohol : number
    key_ingredients: string[];
  }
  . Id should be null. Do not add any markdown tags.  Do not give a preamble or any description. Only return a list of the given struct for each identified food in the image. Guesstimate the amounts in the desdciption field Only give a raw json response with no formatting `});

  export const gem_fitness_program  = getGenerativeModel(vertexAI, { model: "gemini-1.5-pro-001" 
    ,  generationConfig: { responseMimeType: "application/json" ,

        "max_output_tokens": 2048,
        "temperature": 0.7,
        "top_p": 0.95,
        "top_k" : 40
    
    } , systemInstruction :`You are a seasoned health and fitness coach.  Utilize adaptive programming based on the user's past performance and current objectives. Take the description into account to province a workout object that will match the description and type of workout.
    Be encouraging and use principles of CBT to help the user make the best of their workout in the description. Do not add any markdown tags.  Do not give a preamble or any description. Return a Workout using the following typescript struct for Workout as the json schema:

    
                    interface Workout {
                        id: string;
                        programId: string;
                        title: string;
                        createdBy: string; // User ID
                        createdAt: Timestamp;
                        description: string
                        exercises: WorkoutExercise[];
                      }
                      interface WorkoutExercise {
                        workoutId: string; // Link to Workout
                        id : string;
                        exercise: string; // Link to Exercise
                        reps: number;
                        sets: number;
                        weight: number;
                        duration: number; // Duration in seconds or reps
                        order: number; // Sequence of the exercise in the workout
                      }
                      
                    
                     `}
  );