import { collection, addDoc, Timestamp, updateDoc, deleteDoc, getDoc, doc, getDocs, query, where, DocumentReference, orderBy, startAfter, limit, endAt} from "firebase/firestore";
import {db, storage} from "../firebase";
import { TimelineEvent, UpdateTimelineEventFields } from "../types/timeline";
import { ref, deleteObject } from "firebase/storage";


async function createTimelineEvent(entry: TimelineEvent) {
  try {
    const docRef: DocumentReference = await addDoc(collection(db, "TimelineEvents"), {
      ...entry,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    
    }
    );
    entry.id = docRef.id
    updateTimelineEvent(entry.id, entry)
    return entry
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}


async function getTimelineEvent(id : string) {
  const docRef = doc(db, "TimelineEvents", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}
async function getTimelineEvents(uid : string) {
  const q = query(collection(db, "TimelineEvents"), where("userId", "==", uid), orderBy("createdAt", "desc")  );
      const querySnapshot = await getDocs(q);
      const fetchedEntries: TimelineEvent[] = [];
      querySnapshot.forEach(doc => {
        let entry = doc.data() as TimelineEvent
        entry.id = doc.id
        fetchedEntries.push({
          ...entry
        });
      });
   
return fetchedEntries
}
async function updateTimelineEvent(id: string, updatedFields:UpdateTimelineEventFields) {
  const docRef = doc(db, "TimelineEvents", id);
  try {
    await updateDoc(docRef, {
      ...updatedFields,
      updatedAt: Timestamp.now()
    });
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}



async function deleteTimelineEvent(id : string, img : string| undefined) {
  const docRef = doc(db, "TimelineEvents", id);
  try {
    await deleteDoc(docRef);
    console.error("Document deleted");
  } catch (e) {
    console.error("Error deleting document: ", e);
  }

  

}


async function getPaginatedTimelineEvents(uid: string, startAfterDoc: TimelineEvent | null) {
  let q;
  if (startAfterDoc && startAfterDoc.id) {
    const docRef = doc(db, "TimelineEvents", startAfterDoc.id);
    const startAfterSnapshot = await getDoc(docRef);
    if (startAfterSnapshot.exists()) {
      q = query(
        collection(db, "TimelineEvents"),
        where("userId", "==", uid),
        orderBy("createdAt", "desc"),
        startAfter(startAfterSnapshot),
        limit(10)
      );
    } else {
      console.error("Start after document does not exist");
      return [];
    }
  } else {
    q = query(
      collection(db, "TimelineEvents"),
      where("userId", "==", uid),
      orderBy("createdAt", "desc"),
      limit(10)
    );
  }

  const querySnapshot = await getDocs(q);
  const fetchedEntries: TimelineEvent[] = [];
  querySnapshot.forEach(doc => {
    let entry = doc.data() as TimelineEvent;
    entry.id = doc.id;
    fetchedEntries.push(entry);
  });

  return fetchedEntries;
}



export  {createTimelineEvent, getTimelineEvent, updateTimelineEvent, deleteTimelineEvent, getTimelineEvents, getPaginatedTimelineEvents}