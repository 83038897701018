import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import html2canvas from 'html2canvas';
import { storage } from '../firebase'; // Ensure you have Firebase initialized correctly
import { CoachingSettings } from '../types/fitness';
import { ExistApiResponse, ExistAttribute, InsightsApiResponse, UserAttributeMetrics, UserMetricsResponse } from '../types/utils';
import TurndownService from 'turndown';

/**
 * Fetches an image from Firebase Storage and converts it to a base64 encoded string.
 * @param imagePath - The path of the image in Firebase Storage.
 * @returns A promise that resolves with the base64 encoded string of the image.
 */
async function getBase64FromFirebaseStorage(imagePath: string): Promise<string> {

    const ref = storageRef(storage, imagePath);
    const url = await getDownloadURL(ref);
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
    });
}

const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            // The result attribute contains the data as a base64 encoded string.
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reader.abort();
            reject(error);
        };
        reader.readAsDataURL(file);
    });
};




async function getMadlibs(): Promise<string> {
    const PAT = process.env.REACT_APP_CLARIFAI_APP_PAT;
    const USER_ID = 'gcp';
    const APP_ID = 'generate';
    const MODEL_ID = 'gemini-1_5-pro';
    const MODEL_VERSION_ID = '3faebb54432e40cf8b74ba9652ff6a88';
    let raw: string;


    raw = JSON.stringify({

        "inputs": [
            {
                "data": {
                    "text": {
                        "raw": `Give me a fun madlib to fill out. The inputs should include nouns, verbs, and adjectives. This is meant for adults. The madlib should take the form of: "Today I saw a [noun] and it was [adj]."
                        }. Do not add any markdown tags.  Do not give a preamble or any description. Simply give me the madlib. There should be no less than three inputs and no more than 6.`
                    }
                }
            }
        ]
    })



    const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Key ${PAT}`
        },
        body: raw
    };

    try {
        const response = await fetch(`https://api.clarifai.com/v2/users/gcp/apps/generate/models/gemini-1_5-pro/versions/3faebb54432e40cf8b74ba9652ff6a88/outputs/`, requestOptions);
        const data = await response.json();
        if (data.status.code !== 10000) {
            console.error('API Error:', data);

            return "";
        }

        // Parse the response data into NutritionInfo objects   

        const output = data.outputs[0].data.text.raw; // Modify this path based on the actual API response

        return output
    } catch (error) {
        console.error('Fetch error:', error);
        return "";
    }

}


const fetchExitsInsights = async (token: string): Promise<InsightsApiResponse> => {
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    const response = await fetch('https://exist.io/api/2/insights/', options);
    const data: InsightsApiResponse = await response.json();
    return data;
};



const savePlanAsJPEG = async (elementId: string) => {
    const element = document.getElementById(elementId);
    if (!element) {
        console.error('Element not found');
        return;
    }

    try {
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/jpeg');

        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'plan.jpg';
        link.click();
    } catch (error) {
        console.error('Error capturing the plan as JPEG:', error);
    }
};


const fetchExistAttributes = async (url: string, token: string): Promise<ExistApiResponse> => {
   
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
            
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return {
            results: data.results,
            next: data.next,
        };
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
};


const fetchExistAvges = async (url: string, token: string): Promise<UserMetricsResponse> => {
   
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
            
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return {
            results: data.results,
            next: data.next,
        };
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
};

const fetchAllExistAttributes = async (token: string, formattedYesterday?: string): Promise<ExistAttribute[]> => {
   
 let fullURL: string
      if (formattedYesterday){
        const params = new URLSearchParams({
            date_max: formattedYesterday,
          });
           fullURL = `https://exist.io/api/2/attributes/with-values?${params.toString()}`;
      }else{
         fullURL = `https://exist.io/api/2/attributes/with-values?`;
      }
    
    let allAttributes: ExistAttribute[] = [];
    let nextUrl: string | null = fullURL;

    while (nextUrl) {
        try {
            const { results, next }: ExistApiResponse = await fetchExistAttributes(nextUrl, token);
            allAttributes = [...allAttributes, ...results];
            nextUrl = next;
        } catch (error) {
            console.error('Error fetching attributes:', error);
            break;
        }
    }

    return allAttributes;
};

const fetchAvgExistAttributes = async (token: string): Promise<UserAttributeMetrics[]> => {
   
    let fullURL: string
    fullURL = `https://exist.io/api/2/averages/?`;
       let allAttributes: UserAttributeMetrics[] = [];
       let nextUrl: string | null = fullURL;
   
       while (nextUrl) {
           try {
               const { results, next }: UserMetricsResponse = await fetchExistAvges(nextUrl, token);
               allAttributes = [...allAttributes, ...results];
               nextUrl = next;
           } catch (error) {
               console.error('Error fetching attributes:', error);
               break;
           }
       }
   
       return allAttributes;
   };


const calculateRMR = async(user_stats: CoachingSettings ): Promise<number>  => {
    if (user_stats.Gender.startsWith("Fem")) {
        return (447.593 + (9.247 * user_stats.weight) + (3.098* user_stats.height_cm) - (4.330 * user_stats.Age)) * 1.2 //light actvity modifier
    } else {
        return ( 88.362 + (13.397 * user_stats.weight) + (4.799 * user_stats.height_cm) - (5.677 * user_stats.Age)) * 1.2 //light actvity modifier
    }

}



function isHTML(text: string): boolean {
  const htmlPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlPattern.test(text);
}

function convertHTMLToMarkdown(html: string): string {
    const turndownService = new TurndownService()
  return turndownService.turndown(html);
}

function processText(text: string): string {
  if (isHTML(text)) {
    return convertHTMLToMarkdown(text);
  }
  return text;
}

export { calculateRMR, fetchAllExistAttributes, fetchAvgExistAttributes, fetchExitsInsights, fileToBase64, getBase64FromFirebaseStorage, getMadlibs, savePlanAsJPEG , processText};
