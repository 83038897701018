import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createWorkoutProgram, deleteWorkoutProgram, getWorkoutProgram, getWorkoutPrograms, updateWorkoutProgram } from '../../functions/fitness';
import { FitnessCategory, WorkoutProgram } from '../../types/fitness';
import { WorkoutListView } from './workout';

interface CreateWorkoutProgramProps {
  open: boolean;
  onClose: () => void;
  programId?: string;
}

const CreateUpdateWorkoutProgram: React.FC<CreateWorkoutProgramProps> = ({ open, onClose, programId }) => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState<FitnessCategory>('general');

  useEffect(() => {
    if (programId) {
      (async () => {
        const program = await getWorkoutProgram(programId);
        if (program) {
          setTitle(program.title);
          setDescription(program.description);
          setCategory(program.category);
        }
      })();
    }
  }, [programId]);

  const handleSave = async () => {
    const thisWorkoutProgram: WorkoutProgram = {
      id: '',
      title,
      description,
      category,
      createdBy: currentUser.uid,
      createdAt: Timestamp.now(),
      workouts: [],
    };

    if (programId) {
      await updateWorkoutProgram(programId, thisWorkoutProgram);
    } else {
      await createWorkoutProgram(thisWorkoutProgram);
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
    setTitle('');
    setDescription('');
    setCategory('general');
  };

  return (
    <Dialog open={open} fullScreen onClose={handleClose} >
      <DialogTitle >
        {programId ? 'Edit Workout Program' : 'Create Workout Program'}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          label="Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          select
          margin="normal"
          fullWidth
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value as FitnessCategory)}
        >
          {(['general', 'strength', 'recovery', 'endurance', 'flexibility', 'balance'] as FitnessCategory[]).map((option) => (
            <MenuItem key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Button onClick={handleClose} color='error'>
          Cancel
        </Button>
        <Button color="warning" onClick={handleSave}  >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface WorkoutProgramProps {
  program: {
    id: string;
    title: string;
    description: string;
  };
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
}

const WorkoutProgramCard: React.FC<WorkoutProgramProps> = ({ program, onUpdate, onDelete }) => {
  const [open, setOpen] = useState(false);
  const handleDelete = () => onDelete(program.id);
  const handleClick = () => setOpen(!open);

  return (
    <Card sx={{ margin: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', borderRadius: 2 }}>
      <CardContent sx={{ paddingBottom: 0 }}>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <ExpandMoreIcon sx={{ color: '#00FFB2' }} />
          </ListItemIcon>
          <ListItemText primary={program.title} secondary={program.description} />
        </ListItemButton>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', padding: '8px 16px' }}>
        <IconButton size="small" onClick={() => onUpdate(program.id)} aria-label="edit" >
          <EditIcon />
        </IconButton>
        <IconButton  size="small" onClick={handleDelete} aria-label="delete" >
          <DeleteIcon color='error' />
        </IconButton>
      </CardActions>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <WorkoutListView programId={program.id} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

const ListWorkoutPrograms: React.FC = () => {
  const { currentUser } = useAuth();
  const [programs, setPrograms] = useState<WorkoutProgram[]>([]);
  const [editProgramId, setEditProgramId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [programIdToDelete, setProgramIdToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchPrograms = async () => {
      const loadedPrograms = await getWorkoutPrograms(currentUser.uid);
      setPrograms(loadedPrograms);
    };
    fetchPrograms();
  }, [currentUser.id]);

  const loadWorkouts = async () => {
    const loadedWorkouts = await getWorkoutPrograms(currentUser.uid);
    setPrograms(loadedWorkouts);
  };

  const handleDelete = (programId: string) => {
    setProgramIdToDelete(programId);
    setConfirmationDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (programIdToDelete) {
      await deleteWorkoutProgram(programIdToDelete);
      loadWorkouts();
      setConfirmationDialogOpen(false);
      setProgramIdToDelete(null);
    }
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    setProgramIdToDelete(null);
  };

  const handleEdit = (programId: string) => {
    setEditProgramId(programId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditProgramId(null);
  };

  return (
    <Box sx={{ pb: 7, pt: 7 }}>
      {programs.map((program) => (
        <WorkoutProgramCard
          key={program.id}
          program={program}
          onUpdate={handleEdit}
          onDelete={handleDelete}
        />
      ))}
      {editProgramId && (
        <CreateUpdateWorkoutProgram
          open={modalOpen}
          onClose={handleCloseModal}
          programId={editProgramId}
        />
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            Are you sure you want to delete this workout program? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export { CreateUpdateWorkoutProgram, ListWorkoutPrograms };
