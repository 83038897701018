import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Box, Button, CardContent, Chip, Collapse, Container, Dialog, Divider, Grid, IconButton, Slide, Slider, Snackbar, TextField, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { createCheckin, deleteCheckinById, getLastCheckinByUser, listCheckinsByUser, updateCheckinById } from '../../functions/checkin'; // Adjust the import based on your project structure
import { Checkin } from '../../types/planner'; // Adjust the import based on your project structure
import { PlanDisplay } from './planner';


interface CheckinCardProps {
    checkin: Checkin;
}

const CheckinCard: React.FC<CheckinCardProps> = ({ checkin }) => {
    const { mood, sore, injuries, energy, busy, notes, date, plan } = checkin;
    const [expanded, setExpanded] = useState(false);

    const getColor = (value: number): string => {
        if (value >= 7) return 'green';
        if (value >= 4) return 'yellow';
        return 'red';
    };
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
        <CardContent>
            <Typography  color="#FFFF00"  variant="h5" gutterBottom>
                Check-in on {date.toDate().toLocaleDateString()}
            </Typography>
            <Divider />
            <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h6">Mood</Typography>
                        <Typography variant="body1" sx={{ color: getColor(mood) }}>{mood}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">Energy</Typography>
                        <Typography variant="body1" sx={{ color: getColor(energy) }}>{energy}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">Busy</Typography>
                        <Typography variant="body1" sx={{ color: getColor((10 -busy)) }}>{busy}</Typography>
                    </Box>
                    
                </Box>
                <IconButton  onClick={handleExpandClick}> 
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}    </IconButton>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box mt={2}>
                    <Typography color={"error"} variant="h6">Sore</Typography>
                    <Typography variant="body1">{sore.join(', ')}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography color={"error"} variant="h6">Injuries</Typography>
                    <Typography variant="body1">{injuries}</Typography>
                </Box>
                <Box mt={1}> 
                    <Typography color='secondary' variant="h6">Notes</Typography>
                    <Typography variant="body1">{notes}</Typography>
                </Box>
         
            <Divider />
        
                <Box >
                <Typography variant="h6">AI PLan</Typography>
                    {plan && JSON. stringify(plan) !== '{}'  && (
                       <PlanDisplay plan={plan} />
                    )}
                </Box>
            </Collapse>
        </CardContent>
    </Box>
    );
};

interface CheckinFormProps {
    checkin: Checkin | null;
    userId: string;
    onClose: () => void; // Add a callback to close the dialog
}

const CheckinForm: React.FC<CheckinFormProps> = ({ checkin, userId, onClose }) => {
    const [formState, setFormState] = useState<Checkin>(checkin ||{
        userId: userId,
        mood: 0,
        sore: [],
        injuries: '',
        energy: 0,
        busy: 0,
        notes: '',
        date: Timestamp.now()
    } as Checkin);
    const [soreInput, setSoreInput] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleSliderChange = (name: keyof Checkin) => (e: Event, value: number | number[]) => {
        setFormState({ ...formState, [name]: value as number });
    };

    const handleSoreInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSoreInput(e.target.value);
    };

    const handleAddSore = () => {
        if (soreInput && !formState.sore.includes(soreInput)) {
            setFormState({ ...formState, sore: [...formState.sore, soreInput] });
            setSoreInput('');
        }
    };

    const handleDeleteSore = (soreToDelete: string) => {
        setFormState({ ...formState, sore: formState.sore.filter(sore => sore !== soreToDelete) });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (checkin && checkin.id) {
                await updateCheckinById(checkin.id, formState);
                setSnackbarMessage('Checkin updated successfully');
            } else {
                await createCheckin(formState);
                setSnackbarMessage('Checkin created successfully');
            }
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            onClose(); // Close the dialog after saving
        } catch (error) {
            setSnackbarMessage('An error occurred while saving the checkin');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" component="div" gutterBottom>
                {checkin ? 'Edit Checkin' : 'Create Checkin'}
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>Mood</Typography>
                        <Slider
                            value={formState.mood}
                            onChange={handleSliderChange('mood')}
                            step={1}
                            marks
                            min={0}
                            max={10}
                            valueLabelDisplay="auto"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            {formState.sore.map((sore) => (
                                <Chip
                                    key={sore}
                                    label={sore}
                                    onDelete={() => handleDeleteSore(sore)}
                                />
                            ))}
                        </Box>
                        <TextField
                            fullWidth
                            label="Add Sore parts"
                            value={soreInput}
                            onChange={handleSoreInputChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddSore();
                                }
                            }}
                        />
                        <Button onClick={handleAddSore} color="primary">
                            Add
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Injuries"
                            type="text"
                            name="injuries"
                            value={formState.injuries}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom>Energy</Typography>
                        <Slider
                            value={formState.energy}
                            onChange={handleSliderChange('energy')}
                            step={1}
                            marks
                            min={0}
                            max={10}
                            valueLabelDisplay="auto"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom>Busy</Typography>
                        <Slider
                            value={formState.busy}
                            onChange={handleSliderChange('busy')}
                            step={1}
                            marks
                            min={0}
                            max={10}
                            valueLabelDisplay="auto"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Notes"
                            multiline
                            rows={4}
                            name="notes"
                            value={formState.notes}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="space-between">
                        <Button onClick={onClose} color="error">
                            Cancel
                        </Button>
                        <Button type="submit"  color="warning">
                            {checkin ? 'Update Checkin' : 'Create Checkin'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};


const Transition = React.forwardRef(function Transition(
    props: { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CheckinList: React.FC = () => {
    const [checkins, setCheckins] = useState<Checkin[]>([]);
    const [open, setOpen] = useState(false);
    const { currentUser } = useAuth();
    const [selectedCheckin, setSelectedCheckin] = useState<Checkin | null>(null);

    useEffect(() => {
        const fetchCheckins = async () => {
            const fetchedCheckins = await listCheckinsByUser(currentUser.uid);
            setCheckins(fetchedCheckins);
        };
        fetchCheckins();
    }, [currentUser]);

    const handleDelete = async (id?: string) => {
        if (id) {
            await deleteCheckinById(id);
            setCheckins(checkins.filter(checkin => checkin.id !== id));
        }
    };

    const handleEdit = (checkin: Checkin) => {
        setSelectedCheckin(checkin);
        setOpen(true);
    };

    const handleCreate = () => {
        setSelectedCheckin(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedCheckin(null);
    };

    return (
        <Box maxWidth="md">
            <Typography variant="h4" component="div" gutterBottom>
                Checkins
            </Typography>
            <Button onClick={handleCreate}>
                Checkin
            </Button>
            <Grid container spacing={2}>
                {checkins.map(checkin => (
                    <Grid item xs={12} key={checkin.id}>
                            <CheckinCard key={checkin.id} checkin={checkin} />
                    </Grid>
                ))}
            </Grid>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
              
                <CheckinForm checkin={selectedCheckin} userId={currentUser.uid} onClose={handleClose} />
            </Dialog>


    
        </Box>
    );
};
export { CheckinCard, CheckinForm, CheckinList };

