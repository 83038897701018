import { Timestamp } from "@firebase/firestore";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, List, ListItem, TextField } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { CycleInstance } from "../../types/utils";

interface CycleInstanceFormProps {
  initInstances: CycleInstance[];
  onInstanceChange: (changedInstances: CycleInstance[]) => void;
}

const InstanceForm: React.FC<CycleInstanceFormProps> = ({ initInstances, onInstanceChange }) => {
  const { currentUser } = useAuth();
  const [instances, setInstances] = useState<CycleInstance[]>(initInstances);

  useEffect(() => {
    setInstances(initInstances);
  }, [initInstances]);

  useEffect(() => {
    onInstanceChange(instances);
  }, [instances, onInstanceChange]);

  const handleInstanceChange = (index: number, field: keyof CycleInstance, value: any) => {
    const updatedInstances = instances.map((instance, idx) => {
      if (idx === index) {
        if (field === 'instanceDate') {
          return {...instance, [field]: Timestamp.fromDate(new Date(value))};
        } else {
          return {...instance, [field]: value};
        }
      }
      return instance;
    });
    setInstances(updatedInstances);
  };

  const addInstance = () => {
    const newIst: CycleInstance = {
      instanceDate: Timestamp.now(), // Set the current timestamp
      note: ''
    };
    setInstances([...instances, newIst]);
  };

  const deleteInstance = (index: number) => {
    const filteredInstances = instances.filter((_, idx) => idx !== index);
    setInstances(filteredInstances);
  };

  return (
    <Box>
      <List>
        {instances.map((instance, index) => (
          <ListItem key={index}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <TextField
                label="Date"
                type="date"
                fullWidth
                value={instance.instanceDate.toDate().toISOString().split('T')[0]} // Display the formatted date
                onChange={e => handleInstanceChange(index, 'instanceDate', e.target.value)}
                margin="normal"
              />
              <TextField
                label="Note"
                fullWidth
                value={instance.note}
                onChange={e => handleInstanceChange(index, 'note', e.target.value)}
                margin="normal"
              />
              <IconButton  color='error'  onClick={() => deleteInstance(index)} sx={{ marginTop: 1 }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
      <Button onClick={addInstance}  color="primary">
        Add Instance
      </Button>
    </Box>
  );
};

export default InstanceForm;
