import { collection, addDoc, Timestamp, updateDoc, deleteDoc, getDoc, doc, getDocs, query, where, DocumentReference, orderBy } from "firebase/firestore";
import { WorkoutProgram, Workout, LoggedWorkout, CoachingSettings } from '../types/fitness';
import { db, gem_fitness_program } from "../firebase";
// Create a WorkoutProgram
async function createWorkoutProgram(program: WorkoutProgram) {
  try {
    const docRef: DocumentReference = await addDoc(collection(db, "workoutPrograms"), {
      ...program,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()

    }
    );
    program.id = docRef.id

    return program
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// Read a WorkoutProgram
async function getWorkoutProgram(programId: string): Promise<WorkoutProgram | undefined> {
  const programRef = doc(db, "workoutPrograms", programId);
  const programSnap = await getDoc(programRef);
  if (programSnap.exists()) {
    return programSnap.data() as WorkoutProgram;
  } else {
    console.error("No such workout program!");
    return undefined;
  }
}

async function getWorkoutPrograms(uid: string): Promise<WorkoutProgram[]> {

  const q = query(collection(db, "workoutPrograms"), where("createdBy", "==", uid));
  const querySnapshot = await getDocs(q);
  const fetchedEntries: WorkoutProgram[] = [];
  querySnapshot.forEach(doc => {
    let program = doc.data() as WorkoutProgram
    program.id = doc.id
    fetchedEntries.push({
      ...program
    });
  });
  return fetchedEntries
}
// Update a WorkoutProgram
async function updateWorkoutProgram(programId: string, updates: Partial<WorkoutProgram>) {
  const programRef = doc(db, "workoutPrograms", programId);
  await updateDoc(programRef, updates);
}

// Delete a WorkoutProgram
async function deleteWorkoutProgram(programId: string) {
  const programRef = doc(db, "workoutPrograms", programId);
  await deleteDoc(programRef);
}

// Create a Workout
async function logWorkout(log: LoggedWorkout) {

  try {
    const docRef: DocumentReference = await addDoc(collection(db, "workout_log"), {
      ...log.workout,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()

    }
    );
    log.workout.id = docRef.id
    return log
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// Read a Workout
async function getLoggedWorkout(workoutId: string): Promise<Workout | undefined> {
  const workoutRef = doc(db, "workout_log", workoutId);
  const workoutSnap = await getDoc(workoutRef);
  if (workoutSnap.exists()) {
    return workoutSnap.data() as Workout;
  } else {
    console.error("No such workout!");
    return undefined;
  }
}



async function getTodayWorkout(userId: string, date?: Date) {
  let startOfDay: Date
  let endOfDay: Date
  if (date) {
    startOfDay = date
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay = date
    endOfDay.setHours(23, 59, 59, 999);
  } else {
    startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);

    endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
  }


  // Convert to Firestore Timestamps
  const startTimestamp = Timestamp.fromDate(startOfDay);
  const endTimestamp = Timestamp.fromDate(endOfDay);

  // Update the Firestore query
  const q = query(
    collection(db, "workout_log"),
    where("createdBy", "==", userId),
    where("createdAt", ">=", startTimestamp),
    where("createdAt", "<=", endTimestamp),
    orderBy("createdAt", "desc")
  );
  const querySnapshot = await getDocs(q);
  const fetchedDtat: Workout[] = [];
  querySnapshot.forEach(doc => {
    const workout = doc.data() as Workout;
    fetchedDtat.push(workout);
  });
  return fetchedDtat;
}


async function getWorkoutLog(userId: string): Promise<Workout[]> {

  const q = query(collection(db, "workout_log"), where("createdBy", "==", userId), orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);
  const fetchedEntries: Workout[] = [];
  querySnapshot.forEach(doc => {
    let program = doc.data() as Workout
    program.id = doc.id
    fetchedEntries.push({
      ...program
    });
  });
  return fetchedEntries
}
// Update a Workout
async function updateLoggedWorkout(workoutId: string, updates: Partial<LoggedWorkout>) {
  const workoutRef = doc(db, "workout_log", workoutId);
  await updateDoc(workoutRef, updates);
}

// Delete a Workout
async function deleteLoggedWorkout(workoutId: string) {
  const workoutRef = doc(db, "workout_log", workoutId);
  await deleteDoc(workoutRef);
}


// Create a Workout
async function createWorkout(workout: Workout) {
workout.id = ""
  try {
    const docRef: DocumentReference = await addDoc(collection(db, "workouts"), {
      ...workout,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()

    }
    );
    workout.id = docRef.id
    return workout
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// Read a Workout
async function getWorkout(workoutId: string): Promise<Workout | undefined> {
  const workoutRef = doc(db, "workouts", workoutId);
  const workoutSnap = await getDoc(workoutRef);
  if (workoutSnap.exists()) {
    return workoutSnap.data() as Workout;
  } else {
    console.error("No such workout!");
    return undefined;
  }
}


async function getWorkouts(programId: string): Promise<Workout[]> {
  const q = query(collection(db, "workouts"), where("programId", "==", programId));
  const querySnapshot = await getDocs(q);
  const fetchedEntries: Workout[] = [];
  querySnapshot.forEach(doc => {
    let program = doc.data() as Workout
    program.id = doc.id
    fetchedEntries.push({
      ...program
    });
  });
  return fetchedEntries
}
// Update a Workout
async function updateWorkout(workoutId: string, updates: Partial<Workout>) {
  const workoutRef = doc(db, "workouts", workoutId);
  await updateDoc(workoutRef, updates);
}

// Delete a Workout
async function deleteWorkout(workoutId: string) {
  const workoutRef = doc(db, "workouts", workoutId);
  await deleteDoc(workoutRef);
}


async function getGeminiWorkout(workout: Workout):Promise<Workout> {
  const result = await gem_fitness_program.generateContent([workout.description]);
  const response = result.response;
  const outputs = (await response).text();
  const res = JSON.parse(outputs) as  Workout;
  res.createdAt = Timestamp.now()
  res.createdBy = workout.createdBy
  res.id = workout.id
  res.programId = workout.programId
return res

}
async function getCoachingSettings( userId: string): Promise<CoachingSettings > {

  const q = query(collection(db, "coachingSettings"), where("userId", "==", userId) );
      const querySnapshot = await getDocs(q);
      const fetchedEntries: CoachingSettings[] = [];
      querySnapshot.forEach(doc => {
        let program = doc.data() as CoachingSettings
        fetchedEntries.push({
          ...program
        });
      });
      return fetchedEntries[0]
}





export {  createWorkout, getGeminiWorkout, createWorkoutProgram, deleteWorkout, deleteWorkoutProgram, getCoachingSettings,updateWorkout, getTodayWorkout, updateWorkoutProgram, getWorkout, getWorkouts, getWorkoutProgram, getWorkoutPrograms, logWorkout, getLoggedWorkout, getWorkoutLog, updateLoggedWorkout, deleteLoggedWorkout }