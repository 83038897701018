import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Typography, Stack, Snackbar, Alert, useTheme, useMediaQuery, LinearProgress } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { updateTodayCheckinCtrlHabits } from '../../functions/checkin';
import { getTodayMeals } from '../../functions/nutrition';
import { fetchAllExistAttributes, fetchAvgExistAttributes, calculateRMR } from '../../functions/utils';
import { CoachingSettings, ChecklistState } from '../../types/fitness';
import { Meal } from '../../types/nutrition';
import { ExistAttribute, UserAttributeMetrics, ExistAttributeValue } from '../../types/utils';
import DailyChecklist from './auto_check';
import { ActivityGauge } from './calorie_guage';
import FitnessFeedback from '../fitness/fitness_feedback';

const ExistData: React.FC = () => {
    const [allData, setAllData] = useState<ExistAttribute[]>([]);
    const [loading, setLoading] = useState(true);
    const [averages, setAverages] = useState<UserAttributeMetrics[]>([]);
    const [stepsProg, setStepsProg] = useState(0);
    const [meals, setMeals] = useState<Meal[]>([]);
    const [settings, setSettings] = useState<CoachingSettings>();
    const { currentUser } = useAuth();

    const [snackbarState, setSnackbarState] = useState({
        open: false,
        message: "",
        severity: "error" as "success" | "error" | "warning" | "info",
    });

        // Function to calculate total nutrition values
        const calculateTotal = (meals: Meal[], nutrient: 'calories' | 'protein' | 'carbs' | 'fat'): number => {
            return meals.reduce((sum, meal) => sum + meal.info.reduce((mealSum, item) => mealSum + item[nutrient], 0), 0);
        };
    // Calculate total nutrition values
    const totalCalories = calculateTotal(meals, 'calories');
    const totalProtein = calculateTotal(meals, 'protein');
    const totalCarbs = calculateTotal(meals, 'carbs');
    const totalFat = calculateTotal(meals, 'fat');

    // Fetch data on component mount
    useEffect(() => {
        if (currentUser?.uid) {
            fetchSettingsAndData(currentUser.uid);
        }
    }, [currentUser]);

    // Update progress and settings when data or settings change
    useEffect(() => {
        if (allData.length && settings) {
            calculateAndSetProgress();
            updateSettingsInDB();
        }
    }, [allData, settings]);



    // Function to fetch settings and data
    const fetchSettingsAndData = async (userId: string) => {
        try {
            const settingsRef = doc(db, "coachingSettings", userId);
            const docSnap = await getDoc(settingsRef);

            if (docSnap.exists()) {
                const fetchedSettings = docSnap.data() as CoachingSettings;
                setSettings(fetchedSettings);

                const [attributes, avgAttributes, todayMeals] = await Promise.all([
                    fetchAllExistAttributes(fetchedSettings.ExistsKey),
                    fetchAvgExistAttributes(fetchedSettings.ExistsKey),
                    getTodayMeals(userId)
                ]);

                setAllData(attributes);
                setAverages(avgAttributes);
                setMeals(todayMeals);
            } 
        } catch (error) {
            console.error("Error fetching settings or data:", error);
            setLoading(false);
           
        }
        setLoading(false);
    };

    // Calculate and set progress
    const calculateAndSetProgress = () => {
        const steps = findLatestValue('Steps')?.value as number;
        const stepsGoal = findLatestValue('Steps goal')?.value as number || 0;
        const progress = (steps / stepsGoal) * 100;
        setStepsProg(progress);
    };

    // Update settings in database
    const updateSettingsInDB = async () => {
        if (!settings) return;

        const settingsRef = doc(db, "coachingSettings", currentUser.uid);
        const weight = findLatestValue("Weight")?.value as number;
        const weeklyAvgCals = (findAvgValue('active_energy')?.overall as number) / 3.966;

        if (weeklyAvgCals > 0) {
            settings.weekly_avg_cals = Math.round(weeklyAvgCals);
        }

        if (weight > 0) {
            settings.weight = weight;
        }

        settings.today_active_cal_burn = (findLatestValue("Active energy burned")?.value as number / 3.966) || 0;
        settings.today_rmr = await calculateRMR(settings);

        try {
            await setDoc(settingsRef, settings);
        } catch (error) {
            console.error("Error saving the document:", error);
            showSnackbar("Error saving settings", "error");
        }
    };

    // Find the latest value for a specific attribute
    const findLatestValue = useCallback((label: string): ExistAttributeValue | undefined => {
        return allData.find(attr => attr.label === label && attr.values[0].value != null)?.values[0];
    }, [allData]);

    // Find the average value for a specific attribute
    const findAvgValue = useCallback((label: string): UserAttributeMetrics | undefined => {
        return averages.find(attr => attr.user_attribute === label);
    }, [averages]);

    // Find the value for a specific attribute today
    const findTodayValue = useCallback((label: string): ExistAttributeValue | undefined => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const attribute = allData.find(attr => attr.label === label);
        if (!attribute) return undefined;

        return attribute.values.find(value => {
            const valueDate = new Date(`${value.date}T00:00:00`);
            valueDate.setHours(0, 0, 0, 0);
            return valueDate.getTime() === today.getTime();
        });
    }, [allData]);

    // Generate checklist state based on the current data
    const generateChecklistState = (): ChecklistState => {
        const getValue = (metricName: string, defaultValue: number = 0) => {
            return findTodayValue(metricName)?.value ?? defaultValue;
        };

        const weight = getValue("Weight");
        const targetProtein = weight as number * 1.5;

        const checklistState = {
            weighIn: weight !== undefined,
            workout: getValue("Workouts") as number  > 0,
            stepsGoal: getValue("Steps") >= getValue("Steps goal", 10000),
            floorGoal: getValue("Floors") >= getValue("Floors goal", 10),
            proteinIntake: totalProtein >= targetProtein,
            sleepWell: getValue("Time asleep") >= getValue("Time asleep goal", 480)
        };

        updateTodayCheckinCtrlHabits(currentUser.uid, checklistState);
        return checklistState;
    };

    // Show snackbar message
    const showSnackbar = (message: string, severity: "success" | "error" | "warning" | "info") => {
        setSnackbarState({ open: true, message, severity });
    };

    // Handle snackbar close
    const handleSnackbarClose = () => {
        setSnackbarState(prev => ({ ...prev, open: false }));
    };

    // Pre-calculate target protein
    const targetProtein = Math.round((findTodayValue("Weight")?.value as number || 0) * 1.5);

    return (
        <Box>
            {loading ? (
                <LinearProgress />
            ) : (
                <Box>
                    <Stack>
                        <Typography color="#FFFF00" >
                            Steps {`${findLatestValue('Steps')?.value} / ${findLatestValue('Steps goal')?.value} `}
                        </Typography>
                        <LinearProgress 
                         variant="determinate"
                            value={stepsProg} 
                        />

                        <Box>
                            <FitnessFeedback
                                projectedBurn={Math.round(settings?.today_rmr || 0)}
                                targetBurn={(settings?.weekly_avg_cals || 0)}
                                currentActivityBurn={Math.round(settings?.today_active_cal_burn || 0)}
                                totalCalories={totalCalories}
                                totalProtein={totalProtein}
                                totalCarbs={totalCarbs}
                                totalFat={totalFat}
                                targetProtein={targetProtein}
                            />
                            <DailyChecklist {...generateChecklistState()} />
                        </Box>
                    </Stack>
                </Box>
            )}
            <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ExistData;
